import React, { Component } from "react";
import Page from "../../containers/page";

import {
    Container,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label
} from "reactstrap";

import FormBuilder from '../../components/forms/formBuilder';
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';

import Isvg from 'react-inlinesvg'
import exportIcon from '../../assets/svg/export.svg';
import garabage from '../../assets/svg/garbage.svg';

import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import ErrorModal from "../../components/errorModal";
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import Checkbox from "../../components/forms/fields/checkbox";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { API_ENDPOINT } from '../../constants';


function parseJSON(response) {
    return response.json().then((json) => {
        return {
            result: json,
            status: response.status,
        };
    });
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class EmailCustomer extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            showPlayer: false,
            showPlayer2: false,
            ...props.initialData,
            form: false,
            useState: true,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            items: [],
            total: 0,
            loading: true,
            groups: [],
            languages: [],
            clinicIdx: 0,
            showForm: false,
            checkedMails: [],
            usresNewsletter: [],
            domen: []
        };
    }

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        "query-group": null,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };

    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
        this.setState({ showForm: true })



        // console.log(this.props[0].location.state)

    }

    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    componentDidUpdate(prevProps, prevState) {

        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();

                }
            );
        }


        if (this.props[0].location.state && this.props[0].location.state.newletter /* && this.state.items.length && !prevState.items.length*/) {

            let checkEmail = [...this.props[0].location.state.newletter.arrStopEmail]
            // console.log(checkEmail)
            this.setState({
                initialValues: {
                    _id: this.props[0].location.state.newletter._id,
                    name: this.props[0].location.state.newletter.name,
                    subject: this.props[0].location.state.newletter.subject,
                    description: this.props[0].location.state.newletter.description,

                },
                domen: this.props[0].location.state.newletter.domen,
                checkedMails: checkEmail
            }, () => {
                this.useDomen(this.state.domen)
                this.props[0].history.push({ state: { newletter: null } })
            })




        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj }, () => this.paginationCalculate());
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };

    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj }, () => this.paginationCalculate());
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj }, () => this.paginationCalculate());
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    stopAnimation = () => {
        this.player.current.play();
    };


    insertOrUpdate = (data) => {

        let arr = []
        for (let i = 0; i < this.state.result.length; i++) {
            let check = false
            for (let j = 0; j < this.state.checkedMails.length; j++) {
                // console.log(this.state.result[i].clinicName == this.state.checkedMails[j])
                if (this.state.result[i].clinicName == this.state.checkedMails[j]) {
                    check = true
                }

            }

            if (!check) {
                arr = [...this.state.result[i].adminEmail, ...arr]
            }

        }
        let emails = []
        for (let k = 0; k < this.state.result.length; k++) {
            for (let j = 0; j < this.state.result[k].adminEmail.length; j++) {
                emails.push(this.state.result[k].adminEmail[j])

            }

        }
        // console.log(arr)
        // console.log(emails)
        this.setState(
            {
                showPlayer: true,
            }, () => {
                this.player.current.play();
                if (!data._id) {

                    // fetch('https://site-api.curoflow.se/data/newsletter/add', {
                    fetch(API_ENDPOINT + '/data/email-customer/add', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ ...data, /*stopEmails: arr,*/ emails: arr, domen: this.state.domen, clinicName: this.state.checkedMails })
                    }).then(parseJSON).then(({ result, status }) => {
                        this.setState({
                            showPlayer: false,
                            showPlayer2: true,
                            checkedMails: [],
                            // domen: '-1'
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.props.changeData(''),
                            this.props.changeDataSubject(''),
                            this.props.changeDataName('')

                        // this.get()
                    })
                }
                else {
                    // fetch('https://site-api.curoflow.se/data/newsletter/' + data._id, {
                    fetch(API_ENDPOINT + '/data/email-customer/' + data._id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({ ...data, /*stopEmails: arr,*/ emails: arr, domen: this.state.domen, clinicName: this.state.checkedMails })
                    }).then(parseJSON).then(({ result, status }) => {
                        this.setState({
                            showPlayer: false,
                            showPlayer2: true,
                            checkedMails: [],
                            // domen: '-1'
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.props.changeData(''),
                            this.props.changeDataSubject(''),
                            this.props.changeDataName('')
                        // this.setState({result: null})

                        // this.get()
                    })
                }
            })
    }

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            // fetch('https://site-api.curoflow.se/data/newsletter/' + id, {
            fetch(API_ENDPOINT + '/data/email-customer/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    export() {
        // fetch("https://site-api.curoflow.se/data/subscribe/export", {
        fetch(API_ENDPOINT + '/data/email-customer/export', {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({}),
        })
            .then((res) => res.json())
            .then((result) => {
                if (result.success) {
                    window.location.href = 'https://site-api.curoflow.se' + `${result.file}`;
                }
                this.setState({
                    success: result.success,
                    file: result.file
                })
            });
    }

    addSubscribers(data) {
        this.setState(
            {
                loading: true
            }, () => {
                if (!this.state.form._id) {
                    // fetch('https://site-api.curoflow.se/data/newsletter/admin/new', {
                    fetch(API_ENDPOINT + '/data/email-customer/admin/new', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(parseJSON).then(({ result, status }) => {
                        this.setState({ newSubscribers: null })
                        this.get()
                    })
                }
            })

    }

    useDomen(data) {
        // this.setState({ domen: null })
        let obj = {
            data: data,
            auth: "HQvGCgwuPMIEWTYfTekaeQgGEWpewgjfrNXNJsTsibyqwLvZWORYwqFlVsdjwwsIIhsqLTrgHxyLEiIWmWxvrkLMpAHucdJDSpFgTHRBndYVCleBcwQfsdnJwwBxpRxYqVXeiCZWbbysmXfFXDIciGkPtxmzHZspgHrsblJydbINIcbJkYqsdAmrIrawFqYskfcqqyTaWfCCLCQpcAKZtWSlqMAlfVfWwjBJKopKHsyNFtwgmCnGDkhKsH"
        }
        // console.log(data)
        // fetch('https://site-api.curoflow.se/data/newsletter/admin/new', {
        fetch(API_ENDPOINT + '/domen-list/qa-admin', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ obj })
        }).then(parseJSON).then(({ result, status }) => {
            // console.log(this.state.params)
            if (result) {
                this.setState({
                    total: result.total,
                    result: result.items
                })
                this.paginationCalculate(result)
            } else {
                this.setState({
                    total: 0,
                    result: [],
                    items: []
                })
            }

        })
    }

    paginationCalculate = (result = false) => {
        let items = []
        if (result.items && result.items.length) {
            // console.log(result, result.items)
            // console.log(this.state.params.page, this.state.params.entries)
            for (let i = Number(this.state.params.page) * Number(this.state.params.entries); i < result.items.length; i++) {
                if (i == Number(this.state.params.page) * Number(this.state.params.entries) + Number(this.state.params.entries)) {
                    break;
                }

                items.push(result.items[i])

            }
        } else {
            // console.log(this.state.params.page, this.state.params.entries)
            for (let i = Number(this.state.params.page) * Number(this.state.params.entries); i < this.state.result.length; i++) {
                if (i == Number(this.state.params.page) * Number(this.state.params.entries) + Number(this.state.params.entries)) {
                    break;
                }

                items.push(this.state.result[i])

            }
        }
        this.setState({ items: items })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }
        // console.log(this.props[0])
        return (
            <div className="dashboard">
                <Container fluid>
                    <div className="panel">
                        <Row>

                            <Col lg="6">

                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                </div>
                                <Label>{'Select domain'}</Label>
                                <Input type="select" className="entries" value={this.state.domen} onChange={(e) => {
                                    let domen = this.state.domen;
                                    domen = e.target.value;
                                    this.setState({ domen, items: [], total: 0, checkedMails: [] }, () => {
                                        this.useDomen(this.state.domen)
                                        this.props.changeData(''),
                                            this.props.changeDataSubject(''),
                                            this.props.changeDataName('')
                                    })
                                }}>
                                    <option value={-1}>{'Select domain'.translate(this.props.lang)}</option>
                                    <option value={'https://devapi.curoflow.se'}>{'DEV'.translate(this.props.lang)}</option>
                                    {/* <option value={'https://tryme-api.curoflow.se'}>{'TRY ME'.translate(this.props.lang)}</option> */}
                                    <option value={'https://testapi.curoflow.se'}>{'TEST'.translate(this.props.lang)}</option>
                                    {/* <option value={'https://dashboardapi.curoflow.se'}>{'DASHBOARD'.translate(this.props.lang)}</option> */}
                                </Input>
                                <FormBuilder dateFormat={this.props.dateFormat}
                                    onSubmit={(data) => {
                                        this.insertOrUpdate(data)
                                    }
                                    }
                                    initialValues={this.state.initialValues}
                                    buttonText={
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Sent'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Send".translate(this.props.lang)
                                        )
                                    }
                                    lang={this.props.lang}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            label: 'Name'.translate(this.props.lang)
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'subject',
                                                                            label: 'Subject'.translate(this.props.lang)
                                                                        },
                                                                        {
                                                                            type: 'html',
                                                                            name: 'description',
                                                                            label: 'Content'.translate(this.props.lang),
                                                                            className: 'estate-textarea-size',
                                                                            height: 500
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                            ]
                                        }
                                    ]}
                                ></FormBuilder>

                            </Col>
                            <Col lg="6">
                                <div className="panel-header" style={{ display: 'flex' }}>
                                    <h6>{' '}</h6>
                                    {/* <button className="btn add-button" onClick={() => this.export()}><Isvg src={exportIcon} /> {'Export'.translate(this.props.lang)}</button> */}
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: '_checked', label: 'Exclude'.translate(this.props.lang) },
                                        { type: 'text', name: 'clinicName', label: 'Customer'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'emails', label: 'Email'.translate(this.props.lang), allowSort: true },


                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                            _checked: <Checkbox checked={this.state.checkedMails.filter(mail => mail == item.clinicName).length} onChange={() => {
                                                let checkedMails = this.state.checkedMails;
                                                let index = this.state.checkedMails.findIndex(mail => mail == item.clinicName)
                                                if (this.state.checkedMails[index]) {
                                                    this.state.checkedMails.splice(index, 1)
                                                } else {
                                                    checkedMails.push(item.clinicName)
                                                }

                                                this.setState({
                                                    checkedMails
                                                })
                                            }} />,
                                            emails: item.adminEmail.map(em => {
                                                return em + '; '
                                            })

                                        }
                                    })}
                                    // actions={
                                    // [
                                    // {
                                    //     component: <Isvg src={edit} className="edit-icon" />,
                                    //     onClick: (item) => this.setState({ form: item })
                                    // },
                                    // {
                                    //     component: <Isvg src={garabage} className="delete-icon" />,
                                    //     onClick: (item) => this.setState({ deleteModal: item })
                                    // },
                                    //     ]
                                    // }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete subscriber'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                                {this.state.error ?
                                    <ErrorModal lang={this.props.lang}
                                        isOpen={this.state.error}
                                        toggle={() => this.setState({ error: null })}
                                    >
                                        {this.state.error.translate(this.props.lang)}
                                    </ErrorModal>
                                    : null}

                                {/* {this.state.newSubscribers ?
                                    <Modal isOpen={this.state.newSubscribers} centered >
                                        <ModalHeader style={{ margin: 'auto' }}>{'Add new subscibers'.translate(this.props.lang)}</ModalHeader>
                                        <ModalBody className="delete-modal">
                                            <Input type="textarea" style={{ height: 100 }} value={this.state.newSubscribers.email} onChange={(e) => {
                                                let newSubscribers = this.state.newSubscribers;
                                                newSubscribers.email = e.target.value;
                                                this.setState({ newSubscribers })
                                            }} />
                                        </ModalBody>
                                        <ModalFooter className="newSubscribers">
                                            <Button color="primary" style={{ margin: 'auto', background: 'red' }} onClick={() => this.addSubscribers(this.state.newSubscribers)}>{'Add'.translate(this.props.lang)}</Button>
                                            <Button color="primary" style={{ margin: 'auto' }} onClick={() => this.setState({ newSubscribers: null })}>{'Close'.translate(this.props.lang)}</Button>
                                        </ModalFooter>
                                    </Modal>
                                    : null} */}

                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }
}

const selector = formValueSelector('form');

export default connect(state => {
    return {
        userLevel: selector(state, 'description'),
        userLevel: selector(state, 'subject'),
        userLevel: selector(state, 'name')

    }


}, {
    changeData: value => change("form", "description", value),
    changeDataSubject: value => change("form", "subject", value),
    changeDataName: value => change("form", "name", value)
})(Page(EmailCustomer));
