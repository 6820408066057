import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Routes from './routes'
import { Provider } from 'react-redux'
import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { withRouter } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';
import ReactGA from 'react-ga';

import { API_ENDPOINT } from './constants';
import incomingCall from './assets/sound/incoming_call.mp3';
import call from './assets/svg/call.svg';
import profilePicture from './assets/images/user.png';
import Isvg from 'react-inlinesvg';
import { Rnd } from 'react-rnd';
import dragIcon from './assets/svg/move.svg';

import { Button } from 'reactstrap';


const APP_VERSION = '1.0.7';



const rootReducer = combineReducers({
  form: formReducer
});

const LOGOUT_TIMEOUT = 20 * 60 * 1000; //ms

const store = createStore(rootReducer)
Number.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}

String.prototype.formatPrice = function (sep = 2) {
  let dec_point = '.';
  let thousands_sep = ',';

  var parts = parseFloat(this).toFixed(sep).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

  return parts.join(dec_point);
}




function generateAlias(str) {
  str = str.toLowerCase();
  str = str.replace(/ä/g, 'a');
  str = str.replace(/ö/g, 'o');
  str = str.replace(/ü/g, 'u');
  str = str.replace(/ß/g, 'b');

  str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
  str = str.replace(/-+/g, '-');

  return str;
}
//web notifications
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const publicVapidKey = 'BAhhWYormQopBNXSFIrv73BSUFEv3enfourjBWuYYG1o_5ZPYmA_0HarJBobZQDuyy_qtaz2Z1sZ8wBRAe_PevU';
//ent web notifications 

if (String.prototype.generateAlias == null) {
  String.prototype.generateAlias = function () {
    return generateAlias(this);
  }
}


Object.translate = function (o, s, lang) {
  if (!o) {
    return '';
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o[lang] ? o[lang] : o['en'];
}


Object.get = function (o, s) {
  if (!o) {
    return null;
  }

  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}


if (String.prototype.translate == null) {
  String.prototype.translate = function (lang) {


    let langs = typeof window !== 'undefined' ? window.langs : null;

    if (!langs) {
      return this;
    }

    if (langs[lang] && langs[lang][this]) {
      return langs[lang][this];
    }
    else return this;
  }
}



class App extends Component {
  constructor(props) {
    super(props);
    this.translate = this.translate.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setLightMode = this.setLightMode.bind(this);


    if (typeof window !== 'undefined')
      window.lang = 'se';


    if (typeof window !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') == -1) {
      let getUserMedia = typeof navigator !== 'undefined' ? navigator.mediaDevices.getUserMedia : null;

      navigator.mediaDevices.getUserMedia = (constraints) => {
        if (!window._webRTCStreams) {
          window._webRTCStreams = [];
        }

        for (let i = 0; i < window._webRTCStreams.length; i++) {
          if (window._webRTCStreams[i]) {
            try {
              window._webRTCStreams[i].getTracks().forEach(track => track.stop());
              window._webRTCStreams[i] = null;
            } catch (e) { console.log(e) }
          }
        }

        return new Promise((resolve, reject) => {
          getUserMedia(constraints).then(stream => {
            window._webRTCStreams.push(stream);

            resolve(stream);

          }).catch((err) => {
            if (constraints && constraints.video && constraints.video.facingMode && constraints.video.facingMode.exact && constraints.video.facingMode.exact == "environment") {
              console.log('Trying to find back camera...')
              for (let i = 0; i < window._webRTCStreams.length; i++) {
                if (window._webRTCStreams[i]) {
                  try {
                    window._webRTCStreams[i].getTracks().forEach(track => track.stop());
                    window._webRTCStreams[i] = null;
                  } catch (e) { console.log(e) }
                }
              }

              try {
                navigator.mediaDevices.enumerateDevices().then(async (devices) => {
                  let backCameras = [];
                  for (let i = 0; i < devices.length; i++) {
                    if (devices[i].kind == "videoinput" && /back|rear|environment/gi.test(devices[i].label)) {
                      console.log('Found back camera', devices[i]);
                      try {
                        constraints.video = { deviceId: devices[i].deviceId };
                        let stream = await getUserMedia(constraints);
                        window._webRTCStreams.push(stream);
                        resolve(stream);
                        return;
                      } catch (e) { console.log('not working...') }
                    }
                  }

                  reject('error back camera not found');

                })
              } catch (err) { reject(err) }

            } else {
              reject(err);
            }
          });
        })
      }
    }

    this.state = {
      lang: 'se',
      lightMode: 0,
      services: [],
      latestNews: [],
      modulesTree: [],
      availablePaths: [],
      ...props.appInitialData,
      linksMeta: {},
      userVerificationInProgress: true,
      dateFormat: 'YYYY-MM-DD',
      sidebarWide: false,
      videoCallX: 0,
      videoCallY: 0,
      logoutMessage: false,
    };

  }

  changeLang = () => {
    let lang = 'se';
    if(this.state.lang == 'se'){
      lang = 'en'
    }
    
    if(lang) {
      this.setState({
        lang
      }, () => {
        if(typeof window != 'undefined'){
          localStorage.setItem('lang', lang)
          if(lang == 'se') {
            //window.location.replace('/')
          } else {
            //window.location.replace('/en')
          }
        }
      })
    } else {
      this.setState({
        lang: 'se'
      }, () => {
        if(typeof window != 'undefined'){
          localStorage.setItem('lang', 'se')
        }
      })
    }
  }

  toggleSidebar = () => {
    const currentState = this.state.sidebarWide
    this.setState({ sidebarWide: !currentState })
  }


  formatName = (title, name) => {
    return this.state.lang == 'se' ? (name ? name : '') + " " + (title ? title : '') : (title ? title : '') + " " + (name ? name : '')
  }

  setLang(lang) {
    this.setState({
      lang: lang
    });
  }
  setLightMode(val) {
    this.setState({
      lightMode: val
    });
  }



  translate(text) {
    return text;
  }


  updateMeta = (data) => {
    this.setState({
      metaTags: data
    })
  }

  generateModulesMeta = (tree) => {
    let linksMeta = {};
    for (let i = 0; i < tree.length; i++) {
      linksMeta[tree[i].link] = tree[i].name;
      if (tree[i].modules) {
        for (let j = 0; j < tree[i].modules.length; j++) {
          linksMeta[tree[i].modules[j].link] = tree[i].modules[j].name;
        }
      }
    }
    this.setState({
      linksMeta
    })
  }

  enableLogoutMessage = () => {
    this.setState({ logoutMessage: true })
  }
  disableLogoutMessage = () => {
    this.setState({ logoutMessage: false })
  }
  verifyUser = (callback = null, setGroupCallback = null, disablePatientCheck = false) => {

    if (localStorage.profile) {
      localStorage.removeItem('bankidlogin');

      this.setState({
        selectedProfile: localStorage.getItem('profile')
      });
    }

    fetch(API_ENDPOINT + `/users/verify`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      this.setState({
        userVerificationInProgress: false
      })
      if (!result.error) {
        console.log(result)


        if (localStorage.bankidlogin) {
          localStorage.setItem('profile', 'doctor')
          localStorage.setItem('userLevel', 20);
          localStorage.removeItem('bankidlogin')

        }
        if (localStorage.loginLandingPage) {

          console.log(result.havePersonalReferrals)
          localStorage.setItem('profile', 'patient')
          localStorage.setItem('userLevel', 2);
          localStorage.removeItem('loginLandingPage')

        }



        if (typeof window !== 'undefined')
          window.lang = result.langKey;

        this.setState({
          uData: result,
          //lang: result.langKey,
          dateFormat: result.langKey == 'se' ? 'YYYY-MM-DD' : 'MM-DD-YYYY',

        }, () => {
          this.forceUpdate()
          if (callback) {
            callback();
          }
        })
        if (result.clinicGroups && result.clinicGroups.length && !this.state.selectedGroup) {
          if (localStorage.getItem('group')) {
            let groupIdx = -1;
            for (let i = 0; i < result.clinicGroups.length; i++) {
              if (result.clinicGroups[i]._id == localStorage.getItem('group')) {
                groupIdx = i;
                break;
              }
            }
            if (groupIdx != -1) {
              this.setState({
                selectedGroup: result.clinicGroups[groupIdx]._id,
                selectedClinic: result.clinicGroups[groupIdx].clinics && result.clinicGroups[groupIdx].clinics[0] ? result.clinicGroups[groupIdx].clinics[0]._id : null
              }, () => {
                if (setGroupCallback) {
                  setGroupCallback();
                }
              })
            } else {
              this.setState({
                selectedGroup: result.clinicGroups[0]._id,
                selectedClinic: result.clinicGroups[0].clinics ? result.clinicGroups[0].clinics[0]._id : null
              }, () => {
                if (setGroupCallback) {
                  setGroupCallback();
                }
              })
            }

          } else {
            this.setState({
              selectedGroup: result.clinicGroups[0]._id,
              selectedClinic: result.clinicGroups[0].clinics ? result.clinicGroups[0].clinics[0]._id : null
            }, () => {
              if (setGroupCallback) {
                setGroupCallback();
              }
            })
          }


        }

        if (!disablePatientCheck) {
          if ((result.userLevel == 1 || result.userLevel == 2) && (!result.userData.name || !result.email || !result.phone || !result.street || !result.city || !result.zip)) {
            this.setState({
              patientAccountModal: true
            })
          } else {
            this.setState({
              patientAccountModal: null
            })
          }


        
        }

        if (typeof localStorage && localStorage.getItem('time') != 'undefined' && localStorage.getItem('time') != null)
          if (!this.logoutInterval) {
            this.logoutInterval = setTimeout(() => {
              if (Math.floor(Date.now() / 1000) - localStorage.getItem('time') > 59 * 20) {
                this.signOut()
                this.enableLogoutMessage()

              }
            }, LOGOUT_TIMEOUT);

          }

        let doctorPermission;
        if (result && result.clinicModules && result.clinicModules.length) {
          for (let i = 0; i < result.clinicModules.length; i++) {
            if (result.clinicModules[i].name == "Vårdutövare") {
              doctorPermission = result.clinicModules[i]._id;
            }
          }

        }

        let isDoctor = false;
        if (result.groupPermissionsUser && result.groupPermissionsUser[this.state.selectedGroup] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && result.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
          isDoctor = true;
        }
        let treeIdExists = false;
        if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
          treeIdExists = true;
        }
        let treeId = 'none';

        if (treeIdExists) {
          treeId = localStorage.getItem('profile');
        }
        fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        }).then(res => {
          return res.json()
        }).then((result) => {
          if (result[0] && this.props.location.pathname == '/') {

            if (result[0].modules && result[0].modules[0]) {
              this.props.history.push(result[0].modules[0].link);
            } else {
              this.props.history.push(result[0].link);
            }
          };

          this.generateModulesMeta(result);
          this.setState({
            modulesTree: result
          })
        });

        fetch(API_ENDPOINT + '/users/modules/available-paths', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`
          },
        }).then(res => {
          return res.json()
        }).then((result) => {
          this.setState({
            availablePaths: result
          })
        });


      } else {
        this.setState({
          uData: null
        }, () => {
          this.forceUpdate()
        })
      }
    })


  }

  componentWillUnmount() {
    if (this.logoutInterval) {
      clearTimeout(this.logoutInterval)
    }
  }

  registerLog = (message) => {
    fetch(API_ENDPOINT + '/data/logs/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
      body: JSON.stringify({
        pathname: this.props.location.pathname,
        search: this.props.location.search,
        message: message,
        timestamp: Math.floor(new Date().getTime() / 1000)
      })
    })
  }

  checkToken = () => {



    fetch(API_ENDPOINT + '/users/verify', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      this.setState({
        userVerificationInProgress: false
      })
      if (result.error) {
        if (this.state.uData) {
          this.signOut()
        } else {
          this.setState({
            uData: null
          }, () => {
            setTimeout(() => {

              this.forceUpdate();

            }, 200);
          })
        }

      }
    })

  }

  triggerPushNotification = async () => {
    try {
      if ('serviceWorker' in navigator) {
        const register = await navigator.serviceWorker.register('/serviceWorker.js', {
          scope: '/'
        });

        const subscription = await register.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
        });
        await fetch(API_ENDPOINT + '/subscribe', {
          method: 'POST',
          body: JSON.stringify(subscription),
          headers: {
            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
            'Content-Type': 'application/json',
          },
        });
      } else {
        console.error('Service workers are not supported in this browser');
      }
    } catch (error) {

    }

  }
  changeProfile = (profile) => {
    if (profile == null) {
      localStorage.removeItem('profile')
    } else {
      localStorage.setItem('profile', profile)
    }


    this.setState({
      selectedProfile: profile
    }, () => {

      let doctorPermission;
      if (this.state.uData && this.state.uData.clinicModules && this.state.uData.clinicModules.length) {
        for (let i = 0; i < this.state.uData.clinicModules.length; i++) {
          if (this.state.uData.clinicModules[i].name == "Vårdutövare") {
            doctorPermission = this.state.uData.clinicModules[i]._id;
          }
        }
      }

      let isDoctor = false;
      if (this.state.uData.groupPermissionsUser && this.state.uData.groupPermissionsUser[this.state.selectedGroup] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissionsUser[this.state.selectedGroup][this.state.selectedClinic].indexOf(doctorPermission) != -1) {
        isDoctor = true;
      }
      let treeIdExists = false;
      if ((result.userLevel == 20 && isDoctor) || (typeof window != 'undefined' && localStorage.getItem('profile') == 'patient')) {
        treeIdExists = true;
      }
      let treeId = 'none';

      if (treeIdExists) {
        treeId = this.state.selectedProfile;
      }

      fetch(API_ENDPOINT + '/users/modules/tree/' + treeId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        if (result[0] && this.props.location.pathname == '/') {

          if (result[0].modules && result[0].modules[0]) {
            this.props.history.push(result[0].modules[0].link);
          } else {
            this.props.history.push(result[0].link);
          }
        };

        this.generateModulesMeta(result);
        this.setState({
          modulesTree: result
        })
      });

      fetch(API_ENDPOINT + '/users/modules/available-paths', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      }).then(res => {
        return res.json()
      }).then((result) => {
        this.setState({
          availablePaths: result
        })
      });
    })

  }


  changeGroup = (group) => {
    if (group == null) {
      localStorage.removeItem('group')
    } else {
      localStorage.setItem('group', group)
    }

    this.setState({
      selectedGroup: group
    }, () => {
      let location = this.props.location
      if (location.pathname && this.state.selectedGroup) {
        let links = {};
        for (let i = 0; i < this.state.modulesTree.length; i++) {
          links[this.state.modulesTree[i].link] = this.state.modulesTree[i];
          if (this.state.modulesTree[i].modules) {
            for (let j = 0; j < this.state.modulesTree[i].modules.length; j++) {
              links[this.state.modulesTree[i].modules[j].link] = this.state.modulesTree[i].modules[j];
            }
          }
        }

        if (links[location.pathname]) {

          let selectedGroupIdx = 0;
          if (this.state.selectedGroup && this.state.uData.clinicGroups) {
            for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
              if (this.state.uData.clinicGroups[i]._id == this.state.selectedGroup) {
                selectedGroupIdx = i;
                break;
              }
            }
          }


          let sub = links[location.pathname];

          if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.state.uData && this.state.uData.groupPermissions && this.state.uData.groupPermissions[this.state.selectedGroup] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic] && this.state.uData.groupPermissions[this.state.selectedGroup][this.state.selectedClinic].indexOf(sub._id) !== -1) &&
            (!sub.clinicEntryModuleCondition ||
              (
                sub.clinicEntryModuleCondition &&
                this.state.uData &&
                this.state.uData.clinicGroups &&
                this.state.uData.clinicGroups[selectedGroupIdx] &&
                this.clinicEntryModuleCondition(this.state.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
              )
            )) && (!sub.uDataCondition || (sub.uDataCondition && this.state.uData && this.uDataCondition(sub.uDataCondition)))) {

          } else {
            if (this.state.modulesTree[0].modules && this.state.modulesTree[0].modules[0]) {
              this.props.history.push(this.state.modulesTree[0].modules[0].link);
            } else {
              this.props.history.push(this.state.modulesTree[0].link);
            }
          }
        }


      }
    })
    if (this.state.uData) {
      for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
        if (this.state.uData.clinicGroups[i]._id == group) {

          this.setState({
            selectedClinic: this.state.uData.clinicGroups[i].clinics ? this.state.uData.clinicGroups[i].clinics[0]._id : null
          })
        }
      }
    }
  }
  changeClinic = (group) => {
    this.setState({
      selectedClinic: group
    })

  }

  getSelectedGroup = () => {
    if (this.state.selectedGroup && this.state.uData.clinicGroups) {
      for (let i = 0; i < this.state.uData.clinicGroups.length; i++) {
        if (this.state.uData.clinicGroups[i]._id == this.state.selectedGroup) {
          return this.state.uData.clinicGroups[i];

        }
      }
    }

  }

  getSelectedClinic = () => {
    let group = this.getSelectedGroup();
    if (group && group.clinics) {
      for (let i = 0; i < group.clinics.length; i++) {
        if (group.clinics[i]._id == this.state.selectedClinic) {
          return group.clinics[i];
        }
      }
    }

  }

  setGroupAlias = (alias) => {
    this.setState({
      groupAlias: alias
    })
  }

  signOut = (disabledRedirect = false) => {
    this.setState({ sidebarWide: false })
    let alias = localStorage.groupAlias;
    let logoutLanding = localStorage.logoutLandingPage;
    let userLevel;
    if (this.state.uData && this.state.uData.userLevel) {
      userLevel = this.state.uData.userLevel;
    } else {
      userLevel = localStorage.getItem('userLevel');
    }
    console.log(userLevel)
    if (localStorage.getItem('loginGroupAlias')) {
      alias = localStorage.getItem('loginGroupAlias');
    }
    let profile = localStorage.profile;
    localStorage.removeItem('authToken');
    localStorage.removeItem('groupAlias');
    localStorage.removeItem('group');
    localStorage.removeItem('time')

    localStorage.removeItem('profile');
    localStorage.removeItem('bankidlogin');
    localStorage.removeItem('loginLandingPage');
    localStorage.removeItem('logoutLandingPage');
    localStorage.removeItem('loginGroupAlias');
    localStorage.removeItem('userLevel');

    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    this.setState({ uData: null, selectedGroup: null, selectedClinic: null }, () => {

      if (alias && logoutLanding && userLevel && userLevel == 20 && profile == 'doctor') {
        this.props.history.push(`/${alias}/login`);
      } else if (alias && logoutLanding && userLevel && (userLevel == 1 || userLevel == 2 || userLevel == 20) && profile == 'patient') {
        this.props.history.push(`/${alias}`);
      } else {
        this.props.history.push(`/login`);
      }

    })
    if (this.logoutInterval) {
      clearTimeout(this.logoutInterval)
    }

  }

  resetLogoutInterval = () => {
    if (this.state.uData) {
      if (this.logoutInterval) {
        clearTimeout(this.logoutInterval);
        this.logoutInterval = null;

        if (Math.floor(Date.now() / 1000) > localStorage.getItem('time') && this.state.uData) {
          localStorage.setItem('time', Math.floor(Date.now() / 1000))
        }

        this.logoutInterval = setTimeout(() => {
          if (Math.floor(Date.now() / 1000) - localStorage.getItem('time') > 59 * 20) {
            this.signOut()
            this.enableLogoutMessage()
          }
        }, LOGOUT_TIMEOUT);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.uData != this.state.uData && !prevState.uData) {
      this.resetLogoutInterval();
    }

  }

  answer = () => {
    if (this.audioIncomingCall) {
      this.audioIncomingCall.pause();
    }

    var strWindowFeatures = "fullscreen=yes";
    this.videoCallWindow = window.open(`/video-call/${this.state.offerData.conversation}?initiator=${this.state.offerData.initiator}`, "_blank", strWindowFeatures);
    this.videoCallWindow.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.videoCallWindow.close();
        }


      }

      , false)
    this.setState({
      offerData: null
    })
  }

  endCall = () => {
    if (this.audioIncomingCall) {
      this.audioIncomingCall.pause();
    }

    this.setState({
      offerData: null
    })
  }




  registerSocketEvents = () => {
    this.socket.on('connect', () => {
      this.setState({
        socketId: this.socket.id
      })
    });

    this.socket.on('reconnect', () => {
      this.socket.emit('clientReconnect', { socketId: this.state.socketId });
      this.setState({
        socketId: this.socket.id
      })
    })

  }

  render() {
    let meta;
    if (this.state.metaTags) {
      meta = {
        title: this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
        description: this.state.metaTags.description ? this.state.metaTags.description : null,
        meta: {
          charset: 'utf-8',
          name: {
            'og:title': this.state.linksMeta && this.state.linksMeta[this.props.location.pathname] ? this.state.linksMeta[this.props.location.pathname][this.state.lang] : this.state.metaTags.title,
            'og:image': this.state.metaTags['og:image'] ? this.state.metaTags['og:image'] : null,
            'og:description': this.state.metaTags.description ? this.state.metaTags.description : null
          }
        }
      };


    }

    return (
      <Provider store={store}>
        {
          meta ?

            <DocumentMeta {...meta}>
            </DocumentMeta>

            :
            null
        }
        <Routes
          {...this.state}
          translate={this.translate}
          setLang={this.setLang}
          changeLang={this.changeLang}
          setLightMode={this.setLightMode}
          serverFetch={this.props.serverFetch}
          initialData={this.props.initialData ? this.props.initialData : {}}
          updateMeta={this.updateMeta}
          verifyUser={this.verifyUser}
          formatName={this.formatName}
          toggleSidebar={this.toggleSidebar}
          registerLog={this.registerLog}
          signOut={this.signOut}
          changeGroup={this.changeGroup}
          changeProfile={this.changeProfile}
          setGroupAlias={this.setGroupAlias}
          changeClinic={this.changeClinic}
          getSelectedGroup={this.getSelectedGroup}
          getSelectedClinic={this.getSelectedClinic}
          resetLogoutInterval={this.resetLogoutInterval}
          disableLogoutMessage={this.disableLogoutMessage}
          updateSidebarScrollTop={(sidebarScrollTop) => this.setState({ sidebarScrollTop })}
          socket={this.socket}
          startVideoCall={(conversation) => {
            var strWindowFeatures = "fullscreen=yes";
            this.videoCallWindow = window.open(`/video-call/${conversation}`, "_blank", strWindowFeatures);
            this.videoCallWindow.addEventListener('message',
              (e) => {
                if (e.data == 'end-video-call') {
                  this.videoCallWindow.close();
                }


              }

              , false)

          }}
          allowCookies={() => {
            localStorage.allowCookies = true;
            this.setState({
              cookies: true
            });
          }}
        />


        {this.state.offerData ?
          <div className="video-call-pop-up">
            <div class="caller">
              <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
              <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
            </div>
            <div className="buttons">
              <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
              <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
            </div>

          </div>
          :
          null

        }

        {
          this.state.refreshRequired ?
            <div className="refresh-required">
              <div>
                <p>{'Curoflow updated to new version, please reload page to continue!'.translate(this.state.lang)}</p>
                <Button color="primary" onClick={() => {
                  window.location.reload();

                }}>{'Reload'.translate(this.state.lang)}</Button>
              </div>
            </div>

            :

            null
        }


      </Provider>

    );

  }




  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.state.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  checkTimePhone = () => {
    if (localStorage.time && Math.floor(Date.now() / 1000) - localStorage.getItem('time') > 59 * 20) {
      this.signOut();
      this.enableLogoutMessage();
    } else {
      this.resetLogoutInterval

    }

  }

  componentDidMount() {
    if(typeof window != 'undefined' && localStorage.lang){
      console.log(localStorage.lang)
      this.setState({ lang: localStorage.lang})
    }else{
      if(typeof window != 'undefined'){
        localStorage.setItem('lang', 'se')
      }
    }


    if (localStorage.time && Math.floor(Date.now() / 1000) - localStorage.getItem('time') > 59 * 20) {
      this.signOut()
      this.enableLogoutMessage()
    }

    document.addEventListener('mousemove', this.resetLogoutInterval)
    document.addEventListener('touchmove', this.checkTimePhone)


    this.props.history.listen((location) => {
      let isMobile = typeof window != 'undefined' && window.innerWidth < 1200;
      if (isMobile && location.pathname != this.props.location.pathname) {
        this.setState({
          sidebarWide: null
        })
      }



    })

    try {
      this.triggerPushNotification();
    } catch (error) {

    }

    if (localStorage.allowCookies) {

      this.setState({
        cookies: true
      });
    }

    this.verifyUser();

    setInterval(this.checkToken, 60000);

    fetch(API_ENDPOINT + '/language-translate', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`
      },
    }).then(res => {
      return res.json()
    }).then((result) => {
      window.langs = result;
      this.forceUpdate();
    });



    window.addEventListener('message',
      (e) => {
        if (e.data == 'end-video-call') {
          this.setState({
            videoCallUrl: null
          })
        }

        if (e.data == 'toggle-video-call') {
          this.setState({
            videoCallMin: !this.state.videoCallMin,
            videoCallX: !this.state.videoCallMin ? typeof window != 'undefined' ? window.innerWidth - 320 : 0 : 0,
            videoCallY: !this.state.videoCallMin ? typeof window != 'undefined' ? 20 : 0 : 0,

          })
        }

      }, false)

    if (this.props.uData) {
      this.setState({
        loggedIn: true
      })
    }

  }

}

export default withRouter(App);
