import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Button

} from 'reactstrap';

import FormBuilder from '../../components/forms/formBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


class SyncToProduction extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    };

    showPlayer = () => {
        this.setState({
            showPlayer: true,
        });
    };

    hidePlayer = () => {
        this.setState({
            showPlayer: false,
        });
    };

    insertOrUpdate = () => {
        this.setState(
            {
                showPlayer: true,
            }, () => {
                this.player.current.play();
                fetch('https://site-api.curoflow.se/data/sync', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(() => {

                    this.setState({
                        showPlayer: false,
                        showPlayer2: true,
                    },
                        () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })
                    this.get();
                })

            })

    }


    render() {

        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <FormGroup>
                                    <label>Sync to production</label>

                                </FormGroup>
                                <FormGroup>
                                    <Button

                                        color='primary' onClick={() => this.insertOrUpdate()}>
                                        {
                                            this.state.showPlayer ? (
                                                <Player
                                                    onEvent={(event) => {
                                                        if (event === "load") this.stopAnimation();
                                                    }}
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                    style={{ height: "20px", width: "20px" }}
                                                ></Player>

                                            ) : this.state.showPlayer2 ? (
                                                <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                    onStateChange={this.toggleVisibility}
                                                    ref={this.player2} // set the ref to your class instance
                                                    autoplay={true}
                                                    loop={true}
                                                    controls={true}
                                                    src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                    style={{ height: "20px", width: "20px" }}
                                                ></Player>
                                                    {'Saved'.translate(this.props.lang)}
                                                </div>
                                            ) : (
                                                "Submit".translate(this.props.lang)
                                            )
                                        }
                                    </Button>
                                    {/* {'Submit'.translate(this.props.lang)}</Button> */}
                                </FormGroup>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(SyncToProduction);