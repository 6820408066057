import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button

} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import eye from '../../assets/svg/eye.svg'
import garabage from '../../assets/svg/garbage.svg'
import moment from 'moment';


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class Concact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                'contact': null
            },
            items: [],
            total: 0,
            loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }

    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch('https://site-api.curoflow.se/data/contact/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                fetch('https://site-api.curoflow.se/data/contact/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }

    stopAnimation = () => {
        this.player.current.play();
    }



    /**
    * Delete contact
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       contact id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch('https://site-api.curoflow.se/data/contact/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }

        console.log(params)
        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <Col xl="12">
                            <div className="panel">
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                         {type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true},
                                         {type: 'text', name: 'title', label: 'Title'.translate(this.props.lang)}, 
                                         {type: 'text', name: 'organization', label: 'Organization'.translate(this.props.lang)},
                                         {type: 'text', name: 'email', label: 'Email'.translate(this.props.lang)},
                                         {type: 'text', name: 'received', label: 'Received'.translate(this.props.lang)}
                                        ]}
                                    items={this.state.items.map(item=> {
                                        return{
                                            ...item,
                                            received: moment.unix(item.tsCreated).format('YYYY-MM-DD HH:mm')
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={eye} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="garbage-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete contact'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <Modal isOpen={this.state.form} centered toggle={() => this.setState({ form: !this.state.form })}>
                        <ModalHeader toggle={() => this.setState({ form: !this.state.form })} close={<button type="button" className="close"
                            style={{ position: 'absolute', top: '15px', right: '15px' }}
                            onClick={() => this.setState({ form: !this.state.form })}>&times;</button>}>{'Contact'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            <FormGroup>
                                <Label>{'Url'.translate(this.props.lang)}</Label>
                                <Input type="text" value={this?.state?.form?.url ? this.state.form.url : 'No Data'.translate(this.props.lang)} disabled ></Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type="text" value={this.state.form.name} disabled ></Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Title".translate(this.props.lang)}</Label>
                                <Input type="text" value={this.state.form.title} disabled> </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Organization".translate(this.props.lang)}</Label>
                                <Input type="text" value={this.state.form.organization} disabled> </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Email".translate(this.props.lang)}</Label>
                                <Input type="text" value={this.state.form.email} disabled> </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Phone".translate(this.props.lang)}</Label>
                                <Input type="text" value={this.state.form.phone} disabled> </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label>{"Message".translate(this.props.lang)}</Label>
                                <Input type="textarea" value={this.state.form.message} disabled style={{height:100}}> </Input>
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter className='buttons-right-blue'>
                            <Button color='primary' onClick={() => this.setState({ form: false })}>{'Close'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>

                    : null}
            </div>
        );
    }
}

export default Page(Concact);