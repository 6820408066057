import React, { Component } from "react";
import Page from "../../containers/page";

import { Container, Row, Col, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";

import ListBuilder from '../../components/listBuilder';
import Isvg from 'react-inlinesvg'
import edit from '../../assets/svg/edit.svg';
import garabage from '../../assets/svg/garbage.svg';
import Image from "../../components/forms/fields/image";
import HtmlImage from "../../components/forms/fields/html";
import DeleteModal from "../../components/deleteModal"
import File from "../../components/forms/fields/file";

import {
  getSearchParams,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import { Player } from "@lottiefiles/react-lottie-player";

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.player2 = React.createRef();
    let initialData = props.initialData ? props.initialData : {};
    this.state = {
      ...initialData,
      params: {
        entries: 10,
        page: 0,
        "query-group": null,
      },
      firstSliderItems: [],
      secondSliderITems: [],
      loading: true,
      homePageData: {},
      checkItems: false,
      thirdSliderItems: [],
      lastSliderITems: []
    };
  }

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };
  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }

    if (this.props.lang && prevProps.lang && prevProps.lang !== this.props.lang) {
      let homePageData = this.state.homePageData
      this.setState({
        homePageData: null
      }, () => {
        this.setState({
          homePageData
        })
      })

    }

    if (!this.state.checkItems && this.state.homePageData && this.state.homePageData.firstSliderItems && this.state.firstSliderItems && !this.state.firstSliderItems.length) {

      this.setState({
        firstSliderItems: this.state.homePageData.firstSliderItems, checkItems: true
      })
    }
    if (!this.state.checkItems && this.state.homePageData && this.state.homePageData.secondSliderITems && this.state.secondSliderITems && !this.state.secondSliderITems.length) {
      this.setState({
        secondSliderITems: this.state.homePageData.secondSliderITems, checkItems: true
      })
    }

    if (!this.state.checkItems && this.state.homePageData && this.state.homePageData.lastSliderITems && this.state.lastSliderITems && !this.state.lastSliderITems.length) {
      this.setState({
        lastSliderITems: this.state.homePageData.lastSliderITems, checkItems: true
      })
    }

    if (!this.state.checkItems && this.state.homePageData && this.state.homePageData.thirdSliderItems && this.state.thirdSliderItems && !this.state.thirdSliderItems.length) {
      this.setState({
        thirdSliderItems: this.state.homePageData.thirdSliderItems, checkItems: true
      })
    }

  }

  stopAnimation = () => {
    this.player.current.play();
  };

  insertOrUpdate = (data) => {


    if (this.state.firstSliderItems && this.state.firstSliderItems.length) {
      data.firstSliderItems = this.state.firstSliderItems
    } else {
      data.firstSliderItems = []
    }

    if (this.state.secondSliderITems && this.state.secondSliderITems.length) {
      data.secondSliderITems = this.state.secondSliderITems
    } else {
      data.secondSliderITems = []
    }
    if (this.state.thirdSliderItems && this.state.thirdSliderItems.length) {
      data.thirdSliderItems = this.state.thirdSliderItems
    } else {
      data.thirdSliderItems = []
    }

    if (this.state.lastSliderITems && this.state.lastSliderITems.length) {
      data.lastSliderITems = this.state.lastSliderITems
    } else {
      data.lastSliderITems = []
    }



    this.setState(
      {
        showPlayer: true,
      },
      () => {
        this.player.current.play();
        console.log(data);
        fetch(API_ENDPOINT + "/data/pages/home", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.error) {
              this.setState({
                error: result.error,
                showPlayer: false,
              });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false });
                  }, 1000);
                }
              );
              this.get();
            }
          });
      }
    );
  };

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }

    return (
      <div className="dashboard">
        <Container fluid>
          <Row>
            <Col lg="12">
              <div className="panel">
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Label>First slider</Label>
                    <Button onClick={() => {
                      this.setState({
                        openHeaderSliderModal: {}
                      })
                    }}> Add </Button>
                  </div>

                  <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    hideFilters={true}
                    hideEntries={true}
                    hidePagination={true}
                    fields={
                      [
                        { type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: true, multilang: true },
                        // { type: 'text', name: 'description', label: 'Description'.translate(this.props.lang), allowSort: true, multilang: true },
                        { type: 'text', name: 'imageOrVideo', label: 'Image or Video'.translate(this.props.lang), allowSort: true },

                      ]
                    }
                    items={this.state.firstSliderItems.map((item, idx) => {
                      return {
                        ...item,
                        edit: idx,

                      }
                    })}
                    // rawItems={this.state.firstSliderItems}
                    actions={
                      [
                        {
                          component: <Isvg src={edit} className="edit-icon" />,
                          onClick: (item) => this.setState({ openHeaderSliderModal: { ...item } })
                        },
                        {
                          component: <Isvg src={garabage} className="delete-icon" />,
                          onClick: (item) => this.setState({ deleteModal: { ...item, firstSlider: true } })
                        },
                      ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                  ></ListBuilder>
                </div>
                {
                  this.state.homePageData ?
                    <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div style={{ width: '50%' }}>
                        <FormGroup>
                          <Label>Title below first slider</Label>
                          <Input value={this.state.homePageData && this.state.homePageData.titleBelowFirstSlider && this.state.homePageData.titleBelowFirstSlider[this.props.lang]} onChange={e => {
                            let homePageData = this.state.homePageData
                            if (!homePageData.titleBelowFirstSlider) {
                              homePageData.titleBelowFirstSlider = {}
                            }

                            homePageData.titleBelowFirstSlider[this.props.lang] = e.target.value

                            this.setState({
                              homePageData
                            })
                          }} />
                        </FormGroup>
                        <FormGroup>
                          <Label>Description below first slider</Label>
                          <HtmlImage value={this.state.homePageData && this.state.homePageData.descriptionBelowFirstSlider && this.state.homePageData.descriptionBelowFirstSlider[this.props.lang]} onChange={e => {
                            let homePageData = this.state.homePageData
                            if (!homePageData.descriptionBelowFirstSlider) {
                              homePageData.descriptionBelowFirstSlider = {}
                            }

                            homePageData.descriptionBelowFirstSlider[this.props.lang] = e

                            this.setState({
                              homePageData
                            })
                          }} />
                        </FormGroup>
                      </div>
                      <div style={{ width: '50%', padding: 15 }}>
                        <FormGroup>
                          <Label>Add image</Label>
                          <Image value={this.state.homePageData?.imageBelowFirstSlider} onChange={(e) => {
                            let homePageData = this.state.homePageData
                            homePageData.imageBelowFirstSlider = e

                            this.setState({
                              homePageData
                            })
                          }} />
                        </FormGroup>
                      </div>

                    </div>
                    :
                    null
                }

                {
                  this.state.homePageData ?
                    <div style={{ marginTop: 15, width: '50%' }}>
                      <FormGroup>
                        <Label>Title above second slider</Label>
                        <Input value={this.state.homePageData && this.state.homePageData.titleAboveSecondSlider && this.state.homePageData.titleAboveSecondSlider[this.props.lang]} onChange={e => {
                          let homePageData = this.state.homePageData
                          if (!homePageData.titleAboveSecondSlider) {
                            homePageData.titleAboveSecondSlider = {}
                          }

                          homePageData.titleAboveSecondSlider[this.props.lang] = e.target.value

                          this.setState({
                            homePageData
                          })
                        }} />
                      </FormGroup>
                      <FormGroup>
                        <Label>Description above second slider</Label>
                        <HtmlImage value={this.state.homePageData && this.state.homePageData.descriptionAboveSecondSlider && this.state.homePageData.descriptionAboveSecondSlider[this.props.lang]} onChange={e => {
                          let homePageData = this.state.homePageData
                          if (!homePageData.descriptionAboveSecondSlider) {
                            homePageData.descriptionAboveSecondSlider = {}
                          }

                          homePageData.descriptionAboveSecondSlider[this.props.lang] = e

                          this.setState({
                            homePageData
                          })
                        }} />
                      </FormGroup>
                    </div>
                    :
                    null
                }


                <div style={{ marginTop: 15 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Label>Second slider</Label>
                    <Button onClick={() => {
                      this.setState({
                        openSecondSliderModal: {}
                      })
                    }}> Add </Button>
                  </div>

                  <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    hideFilters={true}
                    hideEntries={true}
                    hidePagination={true}
                    fields={
                      [
                        { type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: true, multilang: true },
                        { type: 'text', name: 'imageOrVideo', label: 'Image or Video'.translate(this.props.lang), allowSort: true },

                      ]
                    }
                    items={this.state.secondSliderITems.map((item, idx) => {
                      return {
                        ...item,
                        edit: idx,
                      }
                    })}
                    actions={
                      [
                        {
                          component: <Isvg src={edit} className="edit-icon" />,
                          onClick: (item) => this.setState({ openSecondSliderModal: { ...item } })
                        },
                        {
                          component: <Isvg src={garabage} className="delete-icon" />,
                          onClick: (item) => this.setState({ deleteModal: { ...item, secondSlider: true } })
                        },
                      ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                  ></ListBuilder>
                </div>

                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%' }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Title below second slider</Label>
                      <Input value={this.state.homePageData && this.state.homePageData.titleBelowSecondSlider && this.state.homePageData.titleBelowSecondSlider[this.props.lang]} onChange={e => {
                        let homePageData = this.state.homePageData
                        if (!homePageData.titleBelowSecondSlider) {
                          homePageData.titleBelowSecondSlider = {}
                        }

                        homePageData.titleBelowSecondSlider[this.props.lang] = e.target.value

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>
                  <div style={{ width: '50%', padding: 15 }}>
                    {/* <FormGroup>
                      <Label>Add background image</Label>
                      <Image value={this.state.homePageData?.imageBelowSeconSlider} onChange={(e) => {
                        let homePageData = this.state.homePageData
                        homePageData.imageBelowSeconSlider = e

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup> */}
                    <FormGroup>
                      <HtmlImage value={this.state.homePageData && this.state.homePageData.descriptionBelowSecondSlider && this.state.homePageData.descriptionBelowSecondSlider[this.props.lang]} onChange={e => {
                        let homePageData = this.state.homePageData
                        if (!homePageData.descriptionBelowSecondSlider) {
                          homePageData.descriptionBelowSecondSlider = {}
                        }

                        homePageData.descriptionBelowSecondSlider[this.props.lang] = e

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>
                  </div>

                </div>


                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 1</Label>
                      <Image value={this.state.homePageData?.section4?.image1} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image1 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 1</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text1?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text1) section4.text1 = {};

                        section4.text1[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 2</Label>
                      <Image value={this.state.homePageData?.section4?.image2} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image2 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 2</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text2?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text2) section4.text2 = {};

                        section4.text2[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 3</Label>
                      <Image value={this.state.homePageData?.section4?.image3} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image3 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 3</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text3?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text3) section4.text3 = {};

                        section4.text3[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 4</Label>
                      <Image value={this.state.homePageData?.section4?.image4} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image4 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 4</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text4?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text4) section4.text4 = {};

                        section4.text4[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>








                </div>

                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 5</Label>
                      <Image value={this.state.homePageData?.section4?.image5} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image5 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 5</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text5?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text5) section4.text5 = {};

                        section4.text5[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>

                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 6</Label>
                      <Image value={this.state.homePageData?.section4?.image6} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image6 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 6</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text6?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text6) section4.text6 = {};

                        section4.text6[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>
                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 7</Label>
                      <Image value={this.state.homePageData?.section4?.image7} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image7 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 7</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text7?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text7) section4.text7 = {};

                        section4.text7[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>

                  <div style={{ width: '25%', padding: 15 }}>
                    <FormGroup>
                      <Label style={{ marginBottom: '20px' }}>Image 8</Label>
                      <Image value={this.state.homePageData?.section4?.image8} onChange={(e) => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        section4.image8 = e

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4

                        this.setState({
                          homePageData
                        })
                      }} />
                      <Label style={{ marginBottom: '20px' }}>Text 8</Label>
                      <HtmlImage value={this.state.homePageData?.section4?.text8?.[this.props.lang]} onChange={e => {
                        let section4 = this.state.homePageData?.section4;
                        if (!section4) section4 = {};
                        if (!section4.text8) section4.text8 = {};

                        section4.text8[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section4 = section4;

                        this.setState({
                          homePageData
                        })
                      }} />
                    </FormGroup>

                  </div>




                </div>


                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>
                    <FormGroup>
                      <h1>Section 5</h1>
                      <Label style={{ marginBottom: '20px' }}>Title </Label>
                      <HtmlImage value={this.state.homePageData?.section5?.title?.[this.props.lang]} onChange={e => {
                        let section5 = this.state.homePageData?.section5;
                        if (!section5) section5 = {};
                        if (!section5.title) section5.title = {};

                        section5.title[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section5 = section5;

                        this.setState({
                          homePageData
                        })
                      }} />


                    </FormGroup>

                  </div>



                  <div style={{ width: '50%', padding: 15 }}>
                    <FormGroup>

                      <Label style={{ marginBottom: '20px', marginTop: '30px' }}>Subtitle</Label>
                      <HtmlImage value={this.state.homePageData?.section5?.subtitle?.[this.props.lang]} onChange={e => {
                        let section5 = this.state.homePageData?.section5;
                        if (!section5) section5 = {};
                        if (!section5.subtitle) section5.subtitle = {};

                        section5.subtitle[this.props.lang] = e;

                        let homePageData = this.state.homePageData;
                        homePageData.section5 = section5;

                        this.setState({
                          homePageData
                        })
                      }} />




                    </FormGroup>

                  </div>

                </div>



                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>
                    <FormGroup>

                      <label>upload pdf file</label>

                      {
                        this.props.lang === 'se' ? <File value={this.state.homePageData?.section5?.pdfswe} onChange={(e) => {
                          let section5 = this.state.homePageData?.section5;
                          if (!section5) section5 = {};
                          section5.pdfswe = e

                          let homePageData = this.state.homePageData;
                          homePageData.section5 = section5

                          this.setState({
                            homePageData
                          })
                        }} /> :

                          <File value={this.state.homePageData?.section5?.pdfen} onChange={(e) => {
                            let section5 = this.state.homePageData?.section5;
                            if (!section5) section5 = {};
                            section5.pdfen = e

                            let homePageData = this.state.homePageData;
                            homePageData.section5 = section5

                            this.setState({
                              homePageData
                            })
                          }} />
                      }



                    </FormGroup>

                  </div>




                </div>



                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>




                    <label>Title and subtitle left</label>
                    <HtmlImage value={this.state.homePageData?.section5?.titleAndSubtitle?.[this.props.lang]} onChange={e => {
                      let section5 = this.state.homePageData?.section5;
                      if (!section5) section5 = {};
                      if (!section5.titleAndSubtitle) section5.titleAndSubtitle = {};

                      section5.titleAndSubtitle[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section5 = section5;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>
                    <label>Image left</label>
                    <Image value={this.state.homePageData?.section5?.image1} onChange={(e) => {
                      let section5 = this.state.homePageData?.section5;
                      if (!section5) section5 = {};
                      section5.image1 = e

                      let homePageData = this.state.homePageData;
                      homePageData.section5 = section5

                      this.setState({
                        homePageData
                      })
                    }} />


                  </div>

                </div>


                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>




                    <label>Title and subtitle right</label>
                    <HtmlImage value={this.state.homePageData?.section5?.titleAndSubtitle1?.[this.props.lang]} onChange={e => {
                      let section5 = this.state.homePageData?.section5;
                      if (!section5) section5 = {};
                      if (!section5.titleAndSubtitle1) section5.titleAndSubtitle1 = {};

                      section5.titleAndSubtitle1[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section5 = section5;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>
                    <label>Image right</label>
                    <Image value={this.state.homePageData?.section5?.image2} onChange={(e) => {
                      let section5 = this.state.homePageData?.section5;
                      if (!section5) section5 = {};
                      section5.image2 = e

                      let homePageData = this.state.homePageData;
                      homePageData.section5 = section5

                      this.setState({
                        homePageData
                      })
                    }} />


                  </div>

                </div>













                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>




                    <h2>Section 6</h2>
                    <label>Title</label>
                    <HtmlImage value={this.state.homePageData?.section6?.title?.[this.props.lang]} onChange={e => {
                      let section6 = this.state.homePageData?.section6;
                      if (!section6) section6 = {};
                      if (!section6.title) section6.title = {};

                      section6.title[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section6 = section6;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>subtitle</label>
                    <HtmlImage value={this.state.homePageData?.section6?.subtitle?.[this.props.lang]} onChange={e => {
                      let section6 = this.state.homePageData?.section6;
                      if (!section6) section6 = {};
                      if (!section6.subtitle) section6.subtitle = {};

                      section6.subtitle[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section6 = section6;

                      this.setState({
                        homePageData
                      })
                    }} />


                  </div>

                </div>







                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>short description 1</label>
                    <HtmlImage value={this.state.homePageData?.section6?.description1?.[this.props.lang]} onChange={e => {
                      let section6 = this.state.homePageData?.section6;
                      if (!section6) section6 = {};
                      if (!section6.description1) section6.description1 = {};

                      section6.description1[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section6 = section6;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>short description 2</label>
                    <HtmlImage value={this.state.homePageData?.section6?.description2?.[this.props.lang]} onChange={e => {
                      let section6 = this.state.homePageData?.section6;
                      if (!section6) section6 = {};
                      if (!section6.description2) section6.description2 = {};

                      section6.description2[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section6 = section6;

                      this.setState({
                        homePageData
                      })
                    }} />

                    <label>short description 3</label>
                    <HtmlImage value={this.state.homePageData?.section6?.description3?.[this.props.lang]} onChange={e => {
                      let section6 = this.state.homePageData?.section6;
                      if (!section6) section6 = {};
                      if (!section6.description3) section6.description3 = {};

                      section6.description3[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section6 = section6;

                      this.setState({
                        homePageData
                      })
                    }} />


                  </div>

                </div>






                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>
                      <h2>Section 7</h2>
                    <label>Title</label>
                    <HtmlImage value={this.state.homePageData?.section7?.title?.[this.props.lang]} onChange={e => {
                      let section7 = this.state.homePageData?.section7;
                      if (!section7) section7 = {};
                      if (!section7.title) section7.title = {};

                      section7.title[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section7 = section7;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>short description</label>
                    <HtmlImage value={this.state.homePageData?.section7?.description?.[this.props.lang]} onChange={e => {
                      let section7 = this.state.homePageData?.section7;
                      if (!section7) section7 = {};
                      if (!section7.description) section7.description = {};

                      section7.description[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section7 = section7;

                      this.setState({
                        homePageData
                      })
                    }} />




                  </div>

                </div>

                  {/**Third slider list  builder*****************************************************************************************************************/}

                  <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Label>Third slider</Label>
                    <Button onClick={() => {
                      this.setState({
                        openThirdSliderModal: {}
                      })
                    }}> Add </Button>
                  </div>

                  <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    hideFilters={true}
                    hideEntries={true}
                    hidePagination={true}
                    fields={
                      [
                        { type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: true, multilang: true },
                        // { type: 'text', name: 'description', label: 'Description'.translate(this.props.lang), allowSort: true, multilang: true },
                        { type: 'text', name: 'imageOrVideo', label: 'Image or Video'.translate(this.props.lang), allowSort: true },

                      ]
                    }
                    items={this.state.thirdSliderItems.map((item, idx) => {
                      return {
                        ...item,
                        edit: idx,

                      }
                    })}
                    // rawItems={this.state.firstSliderItems}
                    actions={
                      [
                        {
                          component: <Isvg src={edit} className="edit-icon" />,
                          onClick: (item) => this.setState({ openThirdSliderModal: { ...item } })
                        },
                        {
                          component: <Isvg src={garabage} className="delete-icon" />,
                          onClick: (item) => this.setState({ deleteModal: { ...item, thirdSlider: true } })
                        },
                      ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                  ></ListBuilder>
                </div>



                     {/**end Third slider list  *****************************************************************************************************************************/}

                        {/**Third modal******************************************************************************************************************** */}

                        {
          this.state.openThirdSliderModal ?
            <Modal isOpen={this.state.openThirdSliderModal} centered size="lg">
              <ModalHeader>Insert slider data</ModalHeader>
              <ModalBody>
                <div>
                  <div>
                 
                    <FormGroup>
                      <Label>Add image or video</Label>
                      <Image value={this.state.openThirdSliderModal?.imageOrVideo} onChange={(e) => {
                        let openThirdSliderModal = this.state.openThirdSliderModal
                        openThirdSliderModal.imageOrVideo = e

                        this.setState({
                          openThirdSliderModal
                        })
                      }} />
                    </FormGroup>

                    <FormGroup>
                      <Label>Add logo</Label>
                      <Image value={this.state.openThirdSliderModal?.imageLogo} onChange={(e) => {
                        let openThirdSliderModal = this.state.openThirdSliderModal
                        openThirdSliderModal.imageLogo = e

                        this.setState({
                          openThirdSliderModal
                        })
                      }} />
                    </FormGroup>

                  

                    <FormGroup>
                      <Label>Description</Label>
                      <HtmlImage value={this.state.openThirdSliderModal && this.state.openThirdSliderModal.description && this.state.openThirdSliderModal.description[this.props.lang]} onChange={(e) => {
                        let openThirdSliderModal = this.state.openThirdSliderModal
                        if (!openThirdSliderModal.description) {
                          openThirdSliderModal.description = {}
                        }

                        openThirdSliderModal.description[this.props.lang] = e

                        this.setState({
                          openThirdSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>

                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => {
                  if (this.state.openThirdSliderModal.edit || this.state.openThirdSliderModal.edit === 0) {
                    let thirdSliderItems = [...this.state.thirdSliderItems]
                    thirdSliderItems.splice(this.state.openThirdSliderModal.edit, 1, this.state.openThirdSliderModal)

                    this.setState({
                      thirdSliderItems,
                      openThirdSliderModal: null
                    })
                  } else {
                    let thirdSliderItems = [...this.state.thirdSliderItems]
                    thirdSliderItems.push(this.state.openThirdSliderModal)

                    this.setState({
                      thirdSliderItems,
                      openThirdSliderModal: null
                    })
                  }


                }}>Save</Button>
                <Button onClick={() => {
                  this.setState({
                    openThirdSliderModal: null
                  })
                }}>Close</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }




                        {/**end Third modal******************************************************************************************************************** */}




                {/**last slider list builder******************************************************************************************************************************************************************************************************  */}

                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>
                      <h2>Section 8</h2>
                    <label>Title</label>
                    <HtmlImage value={this.state.homePageData?.section8?.title?.[this.props.lang]} onChange={e => {
                      let section8 = this.state.homePageData?.section8;
                      if (!section8) section8 = {};
                      if (!section8.title) section8.title = {};

                      section8.title[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section8 = section8;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>short description</label>
                    <HtmlImage value={this.state.homePageData?.section8?.description?.[this.props.lang]} onChange={e => {
                      let section8 = this.state.homePageData?.section8;
                      if (!section8) section8 = {};
                      if (!section8.description) section8.description = {};

                      section8.description[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section8 = section8;

                      this.setState({
                        homePageData
                      })
                    }} />




                  </div>

                </div>



                <div style={{ marginTop: 15 }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Label>Last slider</Label>
                    <Button onClick={() => {
                      this.setState({
                        openLastSliderModal: {}
                      })
                    }}> Add </Button>
                  </div>

                  <ListBuilder
                    lang={this.props.lang}
                    loading={this.state.loading}
                    total={this.state.total}
                    showNumeration={false}
                    hideFilters={true}
                    hideEntries={true}
                    hidePagination={true}
                    fields={
                      [
                        { type: 'text', name: 'title', label: 'Title'.translate(this.props.lang), allowSort: true, multilang: true },
                        { type: 'text', name: 'imageOrVideo', label: 'Image or Video'.translate(this.props.lang), allowSort: true },

                      ]
                    }
                    items={this.state.lastSliderITems.map((item, idx) => {
                      return {
                        ...item,
                        edit: idx,
                      }
                    })}
                    actions={
                      [
                        {
                          component: <Isvg src={edit} className="edit-icon" />,
                          onClick: (item) => this.setState({ openLastSliderModal: { ...item } })
                        },
                        {
                          component: <Isvg src={garabage} className="delete-icon" />,
                          onClick: (item) => this.setState({ deleteModal: { ...item, lastSlider: true } })
                        },
                      ]
                    }
                    params={params}
                    sortField={params.sortField}
                    sortType={params.sortType}
                    updateSort={this.updateSort}
                    updateParams={this.updateParams}
                    updateMultipleParams={this.updateMultipleParams}
                  ></ListBuilder>
                </div>




                {/**form text */}
                <div style={{ marginTop: 15, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '50%', padding: 15 }}>
                      <h2>Section 9 form</h2>
                    <label>Title</label>
                    <HtmlImage value={this.state.homePageData?.section9?.title?.[this.props.lang]} onChange={e => {
                      let section9 = this.state.homePageData?.section9;
                      if (!section9) section9 = {};
                      if (!section9.title) section9.title = {};

                      section9.title[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section9 = section9;

                      this.setState({
                        homePageData
                      })
                    }} />
                  </div>
                  <div style={{ width: '50%', padding: 15 }}>

                    <label>short description</label>
                    <HtmlImage value={this.state.homePageData?.section9?.description?.[this.props.lang]} onChange={e => {
                      let section9 = this.state.homePageData?.section9;
                      if (!section9) section9 = {};
                      if (!section9.description) section9.description = {};

                      section9.description[this.props.lang] = e;

                      let homePageData = this.state.homePageData;
                      homePageData.section9 = section9;

                      this.setState({
                        homePageData
                      })
                    }} />




                  </div>

                </div>
                  

                  {/**end form text */}














                {/**end last slider builder  ******************************************************************************************************************************************************************************************************/}









                {/**last slider modal ****************************************************************************************************************************************************************************************************************/}

                {
                  this.state.openLastSliderModal ?
                    <Modal isOpen={this.state.openLastSliderModal} centered>
                      <ModalHeader>Insert slider data</ModalHeader>
                      <ModalBody>
                        <div>
                          <div>
                            <FormGroup>
                              <Label>Tab name</Label>
                              <Input value={this.state.openLastSliderModal && this.state.openLastSliderModal.tabname && this.state.openLastSliderModal.tabname[this.props.lang]} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal
                                if (!openLastSliderModal.tabname) {
                                  openLastSliderModal.tabname = {}
                                }

                                openLastSliderModal.tabname[this.props.lang] = e.target.value

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <Label>Title</Label>
                              <Input value={this.state.openLastSliderModal && this.state.openLastSliderModal.title && this.state.openLastSliderModal.title[this.props.lang]} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal
                                if (!openLastSliderModal.title) {
                                  openLastSliderModal.title = {}
                                }

                                openLastSliderModal.title[this.props.lang] = e.target.value

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <Label>Short description</Label>
                              <HtmlImage value={this.state.openLastSliderModal && this.state.openLastSliderModal.description && this.state.openLastSliderModal.description[this.props.lang]} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal
                                if (!openLastSliderModal.description) {
                                  openLastSliderModal.description = {}
                                }

                                openLastSliderModal.description[this.props.lang] = e

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                          </div>
                          <div>
                            <FormGroup>
                              <Label>Add image or video</Label>
                              <Image value={this.state.openLastSliderModal?.imageOrVideo} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal
                                openLastSliderModal.imageOrVideo = e

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                            <FormGroup>
                              <Label>Add link</Label>
                              <Input value={this.state.openLastSliderModal && this.state.openLastSliderModal.link} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal

                                openLastSliderModal.link = e.target.value

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                            <FormGroup>
                              <Label>Add button text</Label>
                              <Input value={this.state.openLastSliderModal && this.state.openLastSliderModal.btnText && this.state.openLastSliderModal.btnText[this.props.lang]} onChange={(e) => {
                                let openLastSliderModal = this.state.openLastSliderModal
                                if (!openLastSliderModal.btnText) {
                                  openLastSliderModal.btnText = {}
                                } else if (typeof openLastSliderModal.btnText === "string") {
                                  openLastSliderModal.btnText = {}
                                }

                                openLastSliderModal.btnText[this.props.lang] = e.target.value

                                this.setState({
                                  openLastSliderModal
                                })
                              }} />
                            </FormGroup>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button onClick={() => {
                          if (this.state.openLastSliderModal.edit || this.state.openLastSliderModal.edit === 0) {
                            let lastSliderITems = [...this.state.lastSliderITems]
                            lastSliderITems.splice(this.state.openLastSliderModal.edit, 1, this.state.openLastSliderModal)

                            this.setState({
                              lastSliderITems,
                              openLastSliderModal: null
                            })
                          } else {
                            let lastSliderITems = [...this.state.lastSliderITems]
                            lastSliderITems.push(this.state.openLastSliderModal)

                            this.setState({
                              lastSliderITems,
                              openLastSliderModal: null
                            }, ()=>{
                              console.log(this.state.lastSliderITems);
                            })
                          }
                            

                        }}>Save</Button>
                        <Button onClick={() => {
                          this.setState({
                            openLastSliderModal: null
                          })
                        }}>Close</Button>
                      </ModalFooter>
                    </Modal>
                    :
                    null
                }

                {/**end last slider modal ***********************************************************************************************************************************************************************************************************/}


  












         













                <div style={{ marginTop: 15 }}>
                  <Button onClick={() => {
                    this.insertOrUpdate(this.state.homePageData)
                  }}>{this.state.showPlayer ? (
                    <Player
                      onEvent={(event) => {
                        if (event === "load") this.stopAnimation();
                      }}
                      onStateChange={this.toggleVisibility}
                      ref={this.player} // set the ref to your class instance
                      autoplay={true}
                      loop={true}
                      controls={true}
                      src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                      style={{ height: "30px", width: "30px" }}
                    ></Player>

                  ) : this.state.showPlayer2 ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                      onStateChange={this.toggleVisibility}
                      ref={this.player2} // set the ref to your class instance
                      autoplay={true}
                      loop={true}
                      controls={true}
                      src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                      style={{ height: "30px", width: "30px" }}
                    ></Player>
                      {'Saved'.translate(this.props.lang)}
                    </div>
                  ) : (
                    "Save".translate(this.props.lang)
                  )}</Button>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
        {
          this.state.openHeaderSliderModal ?
            <Modal isOpen={this.state.openHeaderSliderModal} centered size="lg">
              <ModalHeader>Insert slider data</ModalHeader>
              <ModalBody>
                <div>
                  <div>
                    <FormGroup>
                      <Label>Title</Label>
                      <Input value={this.state.openHeaderSliderModal && this.state.openHeaderSliderModal.title && this.state.openHeaderSliderModal.title[this.props.lang]} onChange={(e) => {
                        let openHeaderSliderModal = this.state.openHeaderSliderModal
                        if (!openHeaderSliderModal.title) {
                          openHeaderSliderModal.title = {}
                        }

                        openHeaderSliderModal.title[this.props.lang] = e.target.value

                        this.setState({
                          openHeaderSliderModal
                        })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Description</Label>
                      <HtmlImage value={this.state.openHeaderSliderModal && this.state.openHeaderSliderModal.description && this.state.openHeaderSliderModal.description[this.props.lang]} onChange={(e) => {
                        let openHeaderSliderModal = this.state.openHeaderSliderModal
                        if (!openHeaderSliderModal.description) {
                          openHeaderSliderModal.description = {}
                        }

                        openHeaderSliderModal.description[this.props.lang] = e

                        this.setState({
                          openHeaderSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Label>Add image or video</Label>
                      <Image value={this.state.openHeaderSliderModal?.imageOrVideo} onChange={(e) => {
                        let openHeaderSliderModal = this.state.openHeaderSliderModal
                        openHeaderSliderModal.imageOrVideo = e

                        this.setState({
                          openHeaderSliderModal
                        })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Add image or video for mobile</Label>
                      <Image value={this.state.openHeaderSliderModal?.imageOrVideoMobile} onChange={(e) => {
                        let openHeaderSliderModal = this.state.openHeaderSliderModal
                        openHeaderSliderModal.imageOrVideoMobile = e

                        this.setState({
                          openHeaderSliderModal
                        })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Add link</Label>
                      <Input value={this.state.openHeaderSliderModal && this.state.openHeaderSliderModal.link} onChange={(e) => {
                        let openHeaderSliderModal = this.state.openHeaderSliderModal

                        openHeaderSliderModal.link = e.target.value

                        this.setState({
                          openHeaderSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => {
                  if (this.state.openHeaderSliderModal.edit || this.state.openHeaderSliderModal.edit === 0) {
                    let firstSliderItems = [...this.state.firstSliderItems]
                    firstSliderItems.splice(this.state.openHeaderSliderModal.edit, 1, this.state.openHeaderSliderModal)

                    this.setState({
                      firstSliderItems,
                      openHeaderSliderModal: null
                    })
                  } else {
                    let firstSliderItems = [...this.state.firstSliderItems]
                    firstSliderItems.push(this.state.openHeaderSliderModal)

                    this.setState({
                      firstSliderItems,
                      openHeaderSliderModal: null
                    })
                  }


                }}>Save</Button>
                <Button onClick={() => {
                  this.setState({
                    openHeaderSliderModal: null
                  })
                }}>Close</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }

        {
          this.state.openSecondSliderModal ?
            <Modal isOpen={this.state.openSecondSliderModal} centered>
              <ModalHeader>Insert slider data</ModalHeader>
              <ModalBody>
                <div>
                  <div>
                    <FormGroup>
                      <Label>Tab name</Label>
                      <Input value={this.state.openSecondSliderModal && this.state.openSecondSliderModal.tabname && this.state.openSecondSliderModal.tabname[this.props.lang]} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal
                        if (!openSecondSliderModal.tabname) {
                          openSecondSliderModal.tabname = {}
                        }

                        openSecondSliderModal.tabname[this.props.lang] = e.target.value

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Label>Title</Label>
                      <Input value={this.state.openSecondSliderModal && this.state.openSecondSliderModal.title && this.state.openSecondSliderModal.title[this.props.lang]} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal
                        if (!openSecondSliderModal.title) {
                          openSecondSliderModal.title = {}
                        }

                        openSecondSliderModal.title[this.props.lang] = e.target.value

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Label>Short description</Label>
                      <HtmlImage value={this.state.openSecondSliderModal && this.state.openSecondSliderModal.description && this.state.openSecondSliderModal.description[this.props.lang]} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal
                        if (!openSecondSliderModal.description) {
                          openSecondSliderModal.description = {}
                        }

                        openSecondSliderModal.description[this.props.lang] = e

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                  <div>
                    <FormGroup>
                      <Label>Add image or video</Label>
                      <Image value={this.state.openSecondSliderModal?.imageOrVideo} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal
                        openSecondSliderModal.imageOrVideo = e

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Add link</Label>
                      <Input value={this.state.openSecondSliderModal && this.state.openSecondSliderModal.link} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal

                        openSecondSliderModal.link = e.target.value

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Add button text</Label>
                      <Input value={this.state.openSecondSliderModal && this.state.openSecondSliderModal.btnText && this.state.openSecondSliderModal.btnText[this.props.lang]} onChange={(e) => {
                        let openSecondSliderModal = this.state.openSecondSliderModal
                        if (!openSecondSliderModal.btnText) {
                          openSecondSliderModal.btnText = {}
                        } else if (typeof openSecondSliderModal.btnText === "string") {
                          openSecondSliderModal.btnText = {}
                        }

                        openSecondSliderModal.btnText[this.props.lang] = e.target.value

                        this.setState({
                          openSecondSliderModal
                        })
                      }} />
                    </FormGroup>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => {
                  if (this.state.openSecondSliderModal.edit || this.state.openSecondSliderModal.edit === 0) {
                    let secondSliderITems = [...this.state.secondSliderITems]
                    secondSliderITems.splice(this.state.openSecondSliderModal.edit, 1, this.state.openSecondSliderModal)

                    this.setState({
                      secondSliderITems,
                      openSecondSliderModal: null
                    })
                  } else {
                    let secondSliderITems = [...this.state.secondSliderITems]
                    secondSliderITems.push(this.state.openSecondSliderModal)

                    this.setState({
                      secondSliderITems,
                      openSecondSliderModal: null
                    })
                  }


                }}>Save</Button>
                <Button onClick={() => {
                  this.setState({
                    openSecondSliderModal: null
                  })
                }}>Close</Button>
              </ModalFooter>
            </Modal>
            :
            null
        }
        {
          this.state.deleteModal ?
            <DeleteModal
              lang={this.props.lang}
              isOpen={this.state.deleteModal}
              toggle={() => this.setState({ deleteModal: null })}
              handler={() => {
                if (this.state.deleteModal.firstSlider) {
                  let firstSliderItems = this.state.firstSliderItems
                  if (firstSliderItems.length === 1) {
                    this.setState({ deleteModal: null, firstSliderItems: [] })
                  } else {
                    firstSliderItems.splice(this.state.deleteModal.edit, 1)

                    this.setState({ deleteModal: null, firstSliderItems: [...firstSliderItems] })
                  }

                } else if (this.state.deleteModal.secondSlider) {
                  let secondSliderITems = this.state.secondSliderITems
                  if (secondSliderITems.length === 1) {
                    this.setState({ deleteModal: null, secondSliderITems: [] })
                  } else {
                    secondSliderITems.splice(this.state.deleteModal.edit, 1)

                    this.setState({ deleteModal: null, secondSliderITems: [...secondSliderITems] })
                  }

                }else if (this.state.deleteModal.thirdSlider) {
                  let thirdSliderItems = this.state.thirdSliderItems
                  if (thirdSliderItems.length === 1) {
                    this.setState({ deleteModal: null, thirdSliderItems: [] })
                  } else {
                    thirdSliderItems.splice(this.state.deleteModal.edit, 1)

                    this.setState({ deleteModal: null, thirdSliderItems: [...thirdSliderItems] })
                  }

                }else if (this.state.deleteModal.lastSlider) {
                  let lastSliderITems = this.state.lastSliderITems
                  if (lastSliderITems.length === 1) {
                    this.setState({ deleteModal: null, lastSliderITems: [] })
                  } else {
                    lastSliderITems.splice(this.state.deleteModal.edit, 1)

                    this.setState({ deleteModal: null, lastSliderITems: [...lastSliderITems] })
                  }

                }

              }}
            >
              {'Delete'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title && this.state.deleteModal.title[this.props.lang]}</strong>?
            </DeleteModal>
            :
            null
        }








      </div>
    );
  }
}

export default Page(HomePage);
