import React, { Component } from 'react';

import Page from '../containers/page';

class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            }
        };
    }

    componentDidMount() {

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
                this.setState({
                    ...data
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }
    }



    toggle = () => {
        this.setState({
            form: !this.state.form
        })
    }



    render() {
        return (
            <div className='home'>
            <div className="bck">

                <div className="content">
                    <h1>Friktionsfria vårdkedjor</h1>
                    <p>En digital plattform sprungen ur vården – för vården – för patienter.<br />
Tillsammans blir vi experter på ert behov och skapar individuellt anpassade lösningar för just er verksamhet. Välkommen till digitaliseringen av vård, alltid med utgångspunkt i vad som tjänar vården och patienterna bäst.</p>
                </div>
                <div className="footer">Copyright &copy; 2021 Curoflow | All rights reserved</div>
            </div>
        </div>

    );


    }
}

export default Page(HomePage);