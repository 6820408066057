import HomePage from './views/homePage';

import WaitingTimeLog from './views/dataManagment/waitingTimeLog';
import Notifications from '././views/user/notifications';

import ManageModules from './views/modules/manageModules';
import SortModules from './views/modules/sortModules';

import UserLevels from './views/users/userLevels';
import Users from './views/users/users';

import Login from './views/loginPage';

import { API_ENDPOINT } from './constants';
import Account from './views/user/account';
import Translation from './views/languages/translation';

import Logs from './views/logs/logs';
import Tracking from './views/logs/tracking';

import ForgotPassword from './views/forgotPassword';
import RecoveryPassword from './views/recoveryPassword';

import Apartment from './views/apartment/apartment';
import Blog from './views/blog/blog';
import Verification from './views/verification';
import EstateType from './views/apartment/estateType';
import Menu from './views/menu/menu';
import Category from './views/menu/category';
import Subcategory from './views/menu/subcategory';
import Questions from './views/questions/questions';
import Contact from './views/dataManagment/contact';
import Support from './views/dataManagment/support';
import SupportCategory from './views/dataManagment/SupportCategory';
import HomePageForm from './views/pages/homePage';
import OurSolutionPage from './views/pages/ourSolutionPage';
import AboutAsPage from './views/pages/aboutAsPage'
import ContactPage from './views/pages/contactPage'
import CustomersTellUs from './views/pages/customersTellUs';
import GdprPage from './views/pages/gdprPage'
import ForPartners from './views/pages/forPartnersPage'
import CoockiesPage from './views/pages/coockiesPage';
import PrivatePolicy from './views/pages/privatePolicy';
import TeamManagment from './views/pages/teamManagment';
import HomeSeoTagPage from './views/seoTags/homeSeoTag';
import NewsSeoTagPage from './views/seoTags/newsSeoTag';
import NewsDetailsSeoTagPage from './views/seoTags/newsDetailsSeoTag';
import OurSolutionSeoTagPage from './views/seoTags/ourSolutionSeoTag';
import AboutAsSeoTagPage from './views/seoTags/aboutAsSeoTag';
import ForPartnersSeoTagPage from './views/seoTags/forPartnersSeoTag';
import CoockiesSeoTagPage from './views/seoTags/coockiesSeoTag';
import GdprPageSeoTagPage from './views/seoTags/gdprSeoTag';
import PrivacyPolicySeoTagPage from './views/seoTags/privacyPolicySeoTag';
import ConctactSeoTag from './views/seoTags/conctactSeoTag';
import FooterPage from './views/pages/foooter';
import SyncProduction from './views/dataManagment/syncProduction';
import NewLetter from './views/pages/newsLetter';
import EmailCustomer from './views/pages/emailCustomer';
import ListOfSubsccribe from './views/pages/listOfSubsccribe';
import ListOfEmailCustomer from './views/pages/listOfEmailCustomer';
import Media from './views/pages/media'
import WeAssist from './views/pages/weAssistPage';
import EditOurSolutionsPageForm from './views/pages/editOurSolutionsPageForm';
import EditWeassistPageForm from './views/pages/editWeassistPageForm';
import EditAboutUsPageForm from './views/pages/editAboutUsPageForm';
import EditNewsPage from './views/pages/editNewsPage';
import News from './views/pages/news';
import Articles from './views/pages/articles';
import EditArticlesPage from './views/pages/editArticlesPage';
import ArticlesSeoTag from './views/seoTags/articlesSeoTag';
import EditIntegrationsPageForm from './views/pages/editIntegrationsPageForm';
import IntegrationsPage from './views/pages/integrationsPage';
import ServiceDescriptionPage from './views/pages/serviceDescriptionPage';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}


export const routes = [

    {
        path: "/",
        component: Account,

        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

        ]

    },

    {
        path: "/login",
        component: Login,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/home-page",
        component: HomePageForm,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/home', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        console.log(result);
                    return {

                        homePageData: result,
                    }
                })
            }
        ]

    },
    {
        path: "/pages/our-solution-page",
        component: OurSolutionPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/our-solution', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },

        ]

    },
    {
        path: "/pages/edit-our-solution-page/:id",
        component: EditOurSolutionsPageForm,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/edit-news-page/:id",
        component: EditNewsPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/edit-articles-page/:id",
        component: EditArticlesPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/edit-weassist-page/:id",
        component: EditWeassistPageForm,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/edit-integrations-page/:id",
        component: EditIntegrationsPageForm,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/edit-about-us-page/:id",
        component: EditAboutUsPageForm,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ]

    },
    {
        path: "/pages/about-us-page",
        component: AboutAsPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/about-us', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/pages/weassist-page",
        component: WeAssist,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/weassist', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/pages/integrations-page",
        component: IntegrationsPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/integrations', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/pages/news",
        component: News,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/news', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/news/contact', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            initialValues: result.newsContactForm
                        }
                })
            },
        ]

    },
    {
        path: "/pages/articles",
        component: Articles,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/articles', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/articles/contact', {
                    method: 'GET',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            initialValues: result.articlesContactForm
                        }
                })
            },
        ]

    },
    {
        path: "/pages/conctact-page",
        component: ContactPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/contact', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/gdpr-page",
        component: GdprPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/gdpr', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/customers-tell-us-page",
        component: CustomersTellUs,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/customers-tell-us', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/for-partners-page",
        component: ForPartners,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/forpartners', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/coockies-page",
        component: CoockiesPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/coockies', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/service-description-page",
        component: ServiceDescriptionPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/service-description', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/footer-page",
        component: FooterPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/footer', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/private-policy-page",
        component: PrivatePolicy,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/privatepolicy', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/home-seo-tag-page",
        component: HomeSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/home-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/our-solution-seo-tag-page",
        component: OurSolutionSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/our-solution-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/news-seo-tag-page",
        component: NewsSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/news-as-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/articles-seo-tag-page",
        component: ArticlesSeoTag,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/articles-as-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/news-details-seo-tag-page",
        component: NewsDetailsSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/news-details-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },


    {
        path: "/pages/about-as-seo-tag-page",
        component: AboutAsSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/about-as-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/conctact-seo-tag-page",
        component: ConctactSeoTag,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/contact-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/coockies-seo-tag-page",
        component: CoockiesSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/coockies-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/for-partners-seo-tag-page",
        component: ForPartnersSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/for-partners-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/gdpr-seo-tag-page",
        component: GdprPageSeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/gdpr-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/privacy-policy-seo-tag-page",
        component: PrivacyPolicySeoTagPage,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/data/pages/privacy-policy-seo-tag', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)

                        return {
                            initialValues: result,
                        }
                })
            }
        ]

    },
    {
        path: "/pages/newsletter",
        component: NewLetter,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction('https://site-api.curoflow.se/data/newsletter', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/pages/email-customer",
        component: EmailCustomer,
        //redirectUser: '/',
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],

        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {
                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                // return fetchFunction('https://site-api.curoflow.se/data/newsletter', {
                return fetchFunction(API_ENDPOINT + '/data/email-customer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })
            },
        ]

    },
    {
        path: "/sync",
        component: SyncProduction,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Sync to production'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },
    {
        path: "/notifications",
        component: Notifications,
        loginNeeded: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Notifications'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [

        ]

    },



    {
        path: "/forgotpassword",
        component: ForgotPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Nek'.translate(lang),


            }
        },
        loadData: [

        ]

    },


    {
        path: "/forgotpassword/password-recovery/:key",
        component: RecoveryPassword,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Password recovery'.translate(lang) + ' - Nek'.translate(lang),
            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: "/logs/system-logs",
        component: Logs,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/system-logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },

    {
        path: "/logs/user-logs",
        component: Tracking,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/logs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },

    {
        path: "/data-managment/waiting-time-log",
        component: WaitingTimeLog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Waiting time log'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                if (!query.sortField) {
                    query.sortField = 'name';
                    query.sortType = 1;
                }

                return fetchFunction(API_ENDPOINT + '/data/notFoundClinics', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },


        ]
    },
    {
        path: "/modules/manage-modules",
        component: ManageModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Modules'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {
                return fetchFunction(API_ENDPOINT + '/modules/endpoints', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            endpoints: result
                        }
                })

            },

        ]
    },
    {
        path: "/modules/sort-modules",
        component: SortModules,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Modules'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'position',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300) {

                        let tree = [];
                        for (let i = 0; i < result.length; i++) {
                            if (result[i].type == 'menu') {
                                let obj = result[i];
                                obj.submenu = [];
                                for (let j = 0; j < result.length; j++) {
                                    if (result[j].type == 'submenu' && result[j].parent == result[i]._id) {
                                        obj.submenu.push(result[j]);
                                    }

                                }
                                tree.push(obj);
                            }
                        }
                        console.log(tree)
                        return {
                            items: tree
                        }

                    }
                })

            },
        ]
    },

    {
        path: "/users/user-levels",
        component: UserLevels,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'User levels'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/modules', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: null,
                        entries: null,
                        filter: null,
                        sortType: 1,
                        sortField: 'name',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formModules: result
                        }
                })

            },

        ]
    },

    {
        path: "/users/users",
        component: Users,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Users'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/users', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/users/users', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            formUsers: result
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/data/regions', {
                    method: 'GET',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            regions: result,
                        }
                })

            },

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/users/user-levels', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            userLevels: result
                        }
                })

            },

            (fetchFunction, lang, match, query) => {

                return fetchFunction(API_ENDPOINT + '/support-category/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: -1,
                        sortField: 'level',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            supportCategory: result

                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },

        ]
    },
    {
        path: "/my-data/account",
        component: Account,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Account'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/languages', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            languages: result
                        }
                })

            },
        ]
    },
    {
        path: "/apartment/apartment",
        component: Apartment,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Apartment'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/apartment/apartments', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/types/all', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        sortType: 1,
                        sortField: 'key',
                        query: {}
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            estateTypes: result
                        }
                })

            },
        ]
    },
    {
        path: "/apartment/type",
        component: EstateType,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Estate Type'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/apartment/types', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/menu/menu",
        component: Menu,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Menu'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/menu/menues', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/menu/category",
        component: Category,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Category'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/menu/categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/menu/all', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        types: result
                    }
                })

            },
        ]
    },
    {
        path: "/menu/subcategory",
        component: Subcategory,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Subcategory'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/menu/subcategories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
            (fetchFunction, lang, match, query) => {


                return fetchFunction(API_ENDPOINT + '/category/all', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    return {
                        types: result
                    }
                })

            },
        ]
    },
    {
        path: "/blog/blog",
        component: Blog,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Blog'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/blog/blogs', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/questions",
        component: Questions,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Questions'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/questions/questionss', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/languages/translation",
        component: Translation,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Translation'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/languages/translation', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },

    {
        path: "/pages/team-management-page",
        component: TeamManagment,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Management team'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/teams', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/pages/list-newsletters",
        component: ListOfSubsccribe,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Newsletters '.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction('https://site-api.curoflow.se/data/list/subscribers', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/pages/list-email-customer",
        component: ListOfEmailCustomer,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Email customer '.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                // return fetchFunction('https://site-api.curoflow.se/data/list/subscribers', {
                return fetchFunction(API_ENDPOINT + '/data/list/email-customer', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },
    {
        path: "/account/verify/:id/:key",
        component: Verification,
        disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Verification'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [

        ]
    },

    {
        path: "/data/contact",
        component: Contact,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Contact'.translate(lang) + ' - Nek'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                // return fetchFunction('https://site-api.curoflow.se/data/contact', {
                return fetchFunction(API_ENDPOINT + '/data/contact', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: '-1',
                        sortField: "tsCreated",
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/data/support",
        component: Support,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Support'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/support', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }
        ]
    },
    {
        path: "/support-category",
        component: SupportCategory,
        loginNeeded: true,
        preAuthComponent: Login,
        //redirectUser: '/',
        //disableAvailablePaths: true,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Support category'.translate(lang) + ' - Curoflow'.translate(lang),


            }
        },
        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/support-categories', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            }

        ]
    },
    {
        path: "/pages/media",
        component: Media,
        loginNeeded: true,
        preAuthComponent: Login,
        generateSeoTags: (data, lang) => {
            return {
                title: 'Media'.translate(lang),

            }
        },

        loadData: [

        ],
        loadDataWithQuery: [
            (fetchFunction, lang, match, query) => {

                let queryFields = {};
                for (var key in query) {
                    if (key && key.indexOf('query-') === 0) {
                        queryFields[key.replace('query-', '')] = query[key];
                    }
                }

                return fetchFunction(API_ENDPOINT + '/data/pages/media', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        page: query.page,
                        entries: query.entries,
                        filter: query.filter,
                        sortType: query.sortType,
                        sortField: query.sortField,
                        query: queryFields
                    })
                }).then(parseJSON).then(({ result, status }) => {
                    if (status >= 200 && status < 300)
                        return {
                            page: query.page ? query.page : 0,
                            items: result.items,
                            total: result.total,
                        }
                })

            },
        ]
    },

];

export function generateRoutes(r) {
    let newRoutes = [];
    for (let i = 0; i < r.length; i++) {
        newRoutes.push(r[i]);
    }
    return newRoutes;
}