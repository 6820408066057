import React, { Component } from 'react';
import Isvg from "react-inlinesvg";
import searchIcon from '../assets/svg/search.svg';

class Search extends Component {
  state = {
    query: '',
    results: []
  }



  render() {

    return (    

      <div className="search-component">
        <input
          className="search-bar"
          placeholder="Search"
       >
       </input>
       <Isvg src={searchIcon} className="search-icon" />
      </div>
    )
  }
}

export default Search
