import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';

import { Editor } from '@tinymce/tinymce-react';
import { API_ENDPOINT, TINYMCE_SWITCH, HIDE_TINYMCE } from '../../../constants'
import Toggle from './toggle';
/**
* Component for html field
* @author   Milan Stanojevic
*/
class HtmlImage extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {
            showTinymceEditor: false
        };
    }

    selectFile(e) {
        let input = e.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = (e) => {
                this.props.onChange({
                    type: 'image',
                    value: e.target.result,

                })
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

    tinuyMceLog = () => {
        let obj = {
            url: typeof window !== 'undefined' ? window.location.href : null,
        }

        fetch(API_ENDPOINT + '/data/tinymce/log/new', {
            method: 'PUT',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        })
    }

    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}
                {
                    API_ENDPOINT.indexOf('localhost') > -1 && HIDE_TINYMCE ?
                        <div style={{ color: 'red' }}>
                            Tinymce hidden on localhost change HIDE_TINYMCE in constants.js
                        </div>
                        :
                        TINYMCE_SWITCH ?
                            <div className='tiny-mce-container'>
                                <div className='tiny-mce-container-switch'>
                                    <Toggle
                                        label={"Enable editing".translate(this.props.lang)}
                                        value={this.state.showTinymceEditor}
                                        className="toogle-switch-style"
                                        onChange={(val) => {

                                            this.setState({ showTinymceEditor: val.target.checked }, () => {
                                                if (this.state.showTinymceEditor) {
                                                    this.tinuyMceLog();
                                                }
                                            });
                                        }}

                                    />
                                </div>

                                {
                                    this.state.showTinymceEditor ?
                                        <Editor
                                            disabled={this.props.disabled}
                                            apiKey="ubxmhz75z3m6zd19co7o8zl3y4g4vsjnygpm76aq9cfj88d8"
                                            // init={{ plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css' }}
                                            init={{
                                                // plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                                                plugins: 'link table code print image advlist lists', height: this.props.height ? this.props.height : 300, content_css: '/content.css', images_upload_url: `${API_ENDPOINT}/upload/image/tinymceUpload`, image_advtab: true, file_picker_types: 'file image media', file_picker_callback: function (callback, value, meta) {
                                                    // Provide file and text for the link dialog
                                                    if (meta.filetype == 'file') {
                                                        callback('mypage.html', { text: 'My text' });
                                                    }

                                                    // Provide image and alt text for the image dialog
                                                    if (meta.filetype == 'image') {
                                                        callback('myimage.jpg', { alt: 'My alt text' });

                                                    }

                                                    // Provide alternative source and posted for the media dialog
                                                    if (meta.filetype == 'media') {
                                                        callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                                                    }
                                                },
                                                // toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                                toolbar: 'undo redo | bold italic | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist',
                                            }}
                                            value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                                            onEditorChange={(val) => {

                                                if (this.props.multilang) {
                                                    let value = this.props.value;
                                                    if (!value) {
                                                        value = {};
                                                    }
                                                    value[this.props.lang] = val;

                                                    this.props.onChange(value);
                                                } else {

                                                    this.props.onChange(val);
                                                }
                                                this.forceUpdate();


                                            }} />
                                        :
                                        this.props.value && this.props.lang ?
                                            <div className='tiny-mce-read-only-content'>
                                                <div dangerouslySetInnerHTML={{ __html: this.props.value[this.props.lang] }} onChange={() => { }}></div>
                                            </div>

                                            :
                                            <div className='tiny-mce-read-only-content'> {"No content".translate(this.props.lang)} </div>

                                }
                            </div>
                            :
                            <Editor
                                disabled={this.props.disabled}
                                apiKey="ubxmhz75z3m6zd19co7o8zl3y4g4vsjnygpm76aq9cfj88d8"
                                // init={{ plugins: 'link table code print image', height: this.props.height ? this.props.height : 500, content_css: '/content.css' }}
                                init={{
                                    // plugins: 'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                                    plugins: 'link table code print image advlist lists', height: this.props.height ? this.props.height : 300, content_css: '/content.css', images_upload_url: `${API_ENDPOINT}/upload/image/tinymceUpload`, image_advtab: true, file_picker_types: 'file image media', file_picker_callback: function (callback, value, meta) {
                                        // Provide file and text for the link dialog
                                        if (meta.filetype == 'file') {
                                            callback('mypage.html', { text: 'My text' });
                                        }

                                        // Provide image and alt text for the image dialog
                                        if (meta.filetype == 'image') {
                                            callback('myimage.jpg', { alt: 'My alt text' });

                                        }

                                        // Provide alternative source and posted for the media dialog
                                        if (meta.filetype == 'media') {
                                            callback('movie.mp4', { source2: 'alt.ogg', poster: 'image.jpg' });
                                        }
                                    },
                                    // toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                                    toolbar: 'undo redo | bold italic | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist',
                                }}
                                value={this.props.multilang ? (this.props.value && this.props.value[this.props.lang]) ? this.props.value[this.props.lang] : '' : this.props.value}
                                onEditorChange={(val) => {

                                    if (this.props.multilang) {
                                        let value = this.props.value;
                                        if (!value) {
                                            value = {};
                                        }
                                        value[this.props.lang] = val;

                                        this.props.onChange(value);
                                    } else {

                                        this.props.onChange(val);
                                    }
                                    this.forceUpdate();


                                }} />
                }
            </FormGroup>




        );
    }
}

export default HtmlImage;