import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import FormBuilder from '../../components/forms/formBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player} from '@lottiefiles/react-lottie-player';


/**
* About as seo tag
* @author   Milan Stanojevic
*/
class GdprSeoTag extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }
        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }

    stopAnimation = () => {
        this.player.current.play();
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                showPlayer: true,
            }, () => {
                this.player.current.play();
                fetch(API_ENDPOINT + '/data/pages/gdpr-seo-tag', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                        },
                            () => {
                                setTimeout(() => {
                                    this.setState({ showPlayer2: false });
                                }, 1000);
                            })
                        this.get();
                    }
                })
            }
        )
    }

    render() {

        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <FormBuilder dateFormat={this.props.dateFormat}
                                    onSubmit={(data) => {
                                        this.insertOrUpdate(data)
                                    }
                                    }
                                    initialValues={this.state.initialValues}
                                    buttonText={
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Save".translate(this.props.lang)
                                        )
                                    }
                                    lang={this.props.lang}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'h4',
                                                            text: 'Gdpr seo tag'
                                                        },
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'title',
                                                                            label: 'Title'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'textarea',
                                                                            name: 'description',
                                                                            label: 'Description'.translate(this.props.lang),
                                                                            className: 'estate-textarea-size',
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'tags',
                                                                            label: 'Tags'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'url',
                                                                            label: 'Social network url'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'socialTitle',
                                                                            label: 'Social network title'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'type',
                                                                            label: 'Social network type'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'socialDescription',
                                                                            label: 'Social network description'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                        {
                                                                            type: 'image',
                                                                            name: 'image',
                                                                            label: 'Social network image'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                },
                                            ]
                                        }
                                    ]}
                                ></FormBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(GdprSeoTag);