import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import warrning from '../assets/images/warrning.png';


/**
* Delete item modal
* @author   Milan Stanojevic
*/
class DeleteModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (

            <Modal isOpen={this.props.isOpen} centered >
                <ModalHeader style={{margin: 'auto'}}>{ this.props.header ? this.props.header : 'Warning'.translate(this.props.lang)}</ModalHeader>
                <ModalBody className="delete-modal">
                    <img src={warrning} />
                    <div className='message'>
                        {
                            this.props.children
                        }
                    </div>
                </ModalBody>
                <ModalFooter className="buttons-right-blue">
                    <Button type="submit" className="button-black" onClick={() => this.props.handler()}>{'Yes'.translate(this.props.lang)}</Button>{' '}
                    <Button type="submit" className="button-blue" onClick={() => this.props.toggle()} >{'No'.translate(this.props.lang)}</Button>{' '}
                </ModalFooter>
            </Modal>
        )
    }
}

export default DeleteModal;