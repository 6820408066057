import React, { Component } from "react";
import Page from "../../containers/page";

import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

import ModalFormBuilder from "../../components/forms/modalFormBuilder";

import FormBuilder from "../../components/forms/formBuilder";
import { required } from "../../components/forms/validation";

import {
  getSearchParams,
  generateSearchLink,
  generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import ErrorModal from "../../components/errorModal";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";

import { Player } from "@lottiefiles/react-lottie-player";

import profileUser from "../../assets/images/user.png";

function parseJSON(response) {
  return response.json().then((json) => {
    return {
      result: json,
      status: response.status,
    };
  });
}

function parseText(response) {
  return response.text().then(text => {
    return {
      result: text,
      status: response.status
    }
  })
}
/**
* My profile page
* @author   Milan Stanojevic
*/
class Account extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.player2 = React.createRef();
    this.state = {
      showPlayer: false,
      showPlayer2: false,
      ...props.initialData,
      form: false,
      useState: false,
      params: {
        entries: 10,
        page: 0,
        "query-group": null,
      },
      items: [],
      total: 0,
      loading: true,
      groups: [],
      languages: [],
      clinicIdx: 0,
      showForm: false,
    };
  }

  showPlayer = () => {
    this.setState({
      showPlayer: true,
    });
  };

  hidePlayer = () => {
    this.setState({
      showPlayer: false,
    });
  };

  get = () => {
    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
      this.props.loadDataWithQuery[i](
        window.fetch,
        this.props.lang,
        this.props[0].match,
        this.state.useState
          ? this.state.params
          : getSearchParams(this.props[0].location.search, {
            entries: 10,
            page: 0,
            "query-group": null,
          })
      ).then((data) => {
        this.setState(
          {
            ...data,
            loading: null,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  };

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    this.get();
    this.setState({ showForm: true })
  }

  componentDidUpdate(prevProps) {
    if (prevProps[0].location.search != this.props[0].location.search) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.get();
        }
      );
    }

    if (
      typeof prevProps.selectedWaitingTimeType != "undefined" &&
      prevProps.selectedWaitingTimeType != this.props.selectedWaitingTimeType
    ) {
      if (this.props.selectedWaitingTimeType == 0) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 1) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 2) {
        this.props.changeMinValue("");
        this.props.changeMaxValue(0);
      } else if (this.props.selectedWaitingTimeType == 3) {
        this.props.changeMinValue(0);
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 4) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      } else if (this.props.selectedWaitingTimeType == 5) {
        this.props.changeMinValue("");
        this.props.changeMaxValue("");
      }
    }
  }

  updateSort = (field, type) => {
    if (this.state.useState) {
      let state = this.state.params;
      state.sortField = field;
      state.sortType = type;
      this.setState({
        params: state,
      });
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          [
            { name: "sortField", value: field },
            { name: "sortType", value: type },
          ],
          false
        )
      );
    }
  };

  updateParams = (name, value, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        obj[name] = value;
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        obj[name] = value;
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLink(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          name,
          value,
          restart
        )
      );
    }
  };
  updateMultipleParams = (fields, restart = false) => {
    if (this.state.useState) {
      if (restart) {
        let obj = {
          entries: 10,
          page: 0,
        };
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      } else {
        let obj = this.state.params;
        for (let i = 0; i < fields.length; i++) {
          obj[fields[i].name] = fields[i].value;
        }
        this.setState({ params: obj });
      }
    } else {
      this.props[0].history.push(
        generateSearchLinkMultiple(
          this.props[0].location.search,
          { entries: 10, page: 0 },
          fields,
          restart
        )
      );
    }
  };

  stopAnimation = () => {
    this.player.current.play();
  };

  /**
  * Update profile data
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  update = (data) => {
    this.setState(
      {
        showPlayer: true,
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,
                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.forceUpdate()
              });
            }
          });
      }
    );
  };

  /**
  * Update language for user
  * @author   Milan Stanojevic
  * @Objectparam    {Object} data    form data
  */
  updateLanguage = (data) => {
    this.setState(
      {
        showPlayer: true,
        loadLang: true
      },
      () => {
        this.player.current.play();
        fetch(API_ENDPOINT + "/users/account/update-userdata", {
          method: "POST",
          headers: {
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then(parseJSON)
          .then(({ result, status }) => {
            if (result.error) {
              this.setState({ error: result.error, showPlayer: false });
            } else {
              this.setState(
                {
                  message: result.message,
                  showPlayer: false,
                  showPlayer2: true,

                },
                () => {
                  setTimeout(() => {
                    this.setState({ showPlayer2: false, loadLang: false });
                  }, 1000);
                }
              );
              //this.player.current.stop();
              this.props.verifyUser(() => {
                this.setState({
                  showForm: null,
                }, () => {
                  this.setState({
                    showForm: true
                  })
                })
              });
            }
          });
      }
    );
  };


  /**
  * Upload profile image
  * @author   Milan Stanojevic
  */
  uploadImage = (e) => {

    let input = e.target;
    if (input.files && input.files[0]) {
      let fsize = input.files[0].size;
      let file = Math.round((fsize / 1024))

      if (file > 5120) {
        this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
      } else {
        this.setState({
          _loading: true,
        });

        let formData = new FormData();
        formData.append("file", input.files[0]);

        fetch(API_ENDPOINT + "/data/upload-image", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization:
              typeof localStorage !== "undefined"
                ? `Bearer ${localStorage.getItem("authToken")}`
                : null,
          },
          body: formData,
        })
          .then(parseText).then(({ result, status }) => {
            if (status != 200) {
              this.setState({ error: result })
            } else {
              this.updateLanguage({ profilePicture: result });
              this.setState({
                _loading: null
              })
            }

          })
      }
    }
  };

  render() {
    let params = {};
    if (this.state.useState) {
      params = this.state.params;
    } else {
      params = getSearchParams(this.props[0].location.search, {
        entries: 10,
        page: 0,
      });
    }

    const waitingTimeDisabled = [
      [true, true],
      [true, true],
      [false, true],
      [true, false],
      [false, false],
      [false, false],
    ];

    const defaultOptions = {
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };



    return (
      <div className="dashboard">
        <div className={this.props.to}>
          <Container fluid>
            <Row>
              <Col lg="12">

                {this.state.error ? (
                  <ErrorModal lang={this.props.lang}
                    isOpen={this.state.error}
                    toggle={() => this.setState({ error: null })}

                  >
                    {this.state.error.translate(this.props.lang)}
                  </ErrorModal>
                ) : null}

                <div className="panel">
                  <Row>
                    <Col lg="12">
                      <div className="account-title">
                        <h5 className="component-header">
                          {this.props.linksMeta &&
                            this.props.linksMeta[this.props[0].location.pathname]
                            ? this.props.linksMeta[
                            this.props[0].location.pathname
                            ][this.props.lang]
                            : ""}
                        </h5>
                      </div>
                      <div className="account-header">
                        <div className="profile">
                          <div style={{ position: 'relative', marginRight: 15 }}>
                            <img
                              src={
                                this.props.uData && this.props.uData.profilePicture
                                  ? API_ENDPOINT + this.props.uData.profilePicture
                                  : profileUser
                              }
                            />
                            {
                              this.state._loading ?
                                <div className="loader-wrap loader-wrap-account" >
                                  <Player
                                    autoplay={true}
                                    loop={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                                  ></Player>
                                </div>
                                :
                                null
                            }
                          </div>
                          <div
                            className="upload-file"
                            onClick={() => {
                              this.uploadButton.click();
                            }}
                          >
                            {'Upload'.translate(this.props.lang)}
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif"
                              ref={(node) => {
                                this.uploadButton = node;
                              }}
                              onChange={this.uploadImage}
                            />
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              this.update({ profilePicture: null, notifications: this.props.uData.notifications });
                            }}
                          >
                            {'Remove'.translate(this.props.lang)}
                          </button>
                        </div>
                        <div className="language">
                          <label for="languages">{'Language'.translate(this.props.lang)}</label>
                          <select
                            value={this.props.lang}
                            onChange={(e) => {
                              this.props.setLang(e.target.value );
                            }}
                          >
                            <option value="se">Svenska</option>
                            <option value="en">English</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="form-group-row">
                        <div className="form-group-col">
                          <FormGroup className="account-details">
                            {"First and last name".translate(this.props.lang)}
                            : <br /> <strong>{this.props.uData.userData.name}</strong>
                          </FormGroup>

                          <FormGroup className="account-details">
                            {"Email address".translate(this.props.lang)}: <br />{" "}
                            <strong>{this.props.uData.email}</strong>
                          </FormGroup>
                        </div>

                        <div className="form-group-col">
                          {this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2 && (typeof localStorage && localStorage.getItem('profile') != 'patient') ? (
                            <div className="account-details">
                              <FormGroup className="account-details-container">
                                {"User level".translate(this.props.lang)}: <br />{" "}
                                <strong>
                                  {" "}
                                  {Object.translate(this.props.uData, "userLevelName", this.props.lang)}
                                </strong>
                              </FormGroup>
                            </div>
                          ) : null}
                          <FormGroup className="account-details-container">
                            {"Username".translate(this.props.lang)}: <br />{" "}
                            <strong>{this.props.uData.username}</strong>
                          </FormGroup>
                        </div>

                        <div className="form-group-col">


                        </div>
                      </div>

                      {this.state.showForm ?
                        <FormBuilder dateFormat={this.props.dateFormat}
                          lang={this.props.lang}
                          onSubmit={(data) => this.update(data)}
                          initialValues={this.props.uData}
                          buttonText={
                            this.state.showPlayer ? (
                              <Player
                                onEvent={(event) => {
                                  if (event === "load") this.stopAnimation();
                                }}
                                onStateChange={this.toggleVisibility}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                style={{ height: "30px", width: "30px" }}
                              ></Player>

                            ) : this.state.showPlayer2 ? (
                              <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                onStateChange={this.toggleVisibility}
                                ref={this.player2} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                style={{ height: "30px", width: "30px" }}
                              ></Player>
                                {'Saved'.translate(this.props.lang)}
                              </div>
                            ) : (
                              "Save".translate(this.props.lang)
                            )
                          }
                          fields={[
                            {
                              type: "row",
                              children: [

                                {
                                  type: "col",
                                  width: {
                                    lg: 12,
                                    sm: 12,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "text",
                                      name: "userData.name",
                                      label: "Name".translate(this.props.lang),
                                      validate: [required("Name is required!".translate(this.props.lang)),],
                                    },
                                  ],
                                },
                                {
                                  type: "col",
                                  width: {
                                    lg: 12,
                                    sm: 12,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "text",
                                      name: "email",
                                      label: "Email address".translate(this.props.lang),
                                      validate: [required("Email address is required!".translate(this.props.lang)),],
                                    },
                                  ],
                                },
                                {
                                  type: "col",
                                  width: {
                                    lg: 6,
                                    sm: 12,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "phone",
                                      name: "phone",
                                      label: "Phone number".translate(this.props.lang),
                                      validate: [required("Phone is required!".translate(this.props.lang)),],
                                    },
                                  ],
                                },

                                // {
                                //   type: "col",
                                //   width: {
                                //     lg: 12,
                                //     sm: 12,
                                //     xs: 12,
                                //   },
                                //   children: [
                                //     {
                                //       type: "text",
                                //       name: "street",
                                //       label: "Street".translate(this.props.lang),
                                //       // validate: [required("Street is required!".translate(this.props.lang)),],
                                //     },
                                //   ],
                                // },
                                // {
                                //   type: "col",
                                //   width: {
                                //     lg: 9,
                                //     sm: 12,
                                //     xs: 12,
                                //   },
                                //   children: [
                                //     {
                                //       type: "text",
                                //       name: "city",
                                //       label: "City".translate(this.props.lang),
                                //       // validate: [required("City is required!".translate(this.props.lang)),],
                                //     },
                                //   ],
                                // },
                                // {
                                //   type: "col",
                                //   width: {
                                //     lg: 3,
                                //     sm: 12,
                                //     xs: 12,
                                //   },
                                //   children: [
                                //     {
                                //       type: "text",
                                //       name: "zip",
                                //       label: "Postal code".translate(this.props.lang),
                                //       // validate: [required("Postal code is required!".translate(this.props.lang)),],
                                //     },
                                //   ],
                                // },
                                {
                                  type: "col",
                                  width: {
                                    lg: 6,
                                    sm: 6,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "password",
                                      name: "oldPassword",
                                      label: "Old password".translate(this.props.lang),
                                    },
                                  ],
                                },

                                {
                                  type: "col",
                                  width: {
                                    lg: 6,
                                    sm: 12,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "password",
                                      name: "newPassword",
                                      label: "New password".translate(this.props.lang),
                                    },
                                  ],
                                },
                                {
                                  type: "col",
                                  width: {
                                    lg: 6,
                                    sm: 12,
                                    xs: 12,
                                  },
                                  children: [
                                    {
                                      type: "password",
                                      name: "repeatPassword",
                                      label: "Repeat new password".translate(this.props.lang),
                                    },
                                  ],
                                },

                              ],
                            },
                          ]}
                        ></FormBuilder>
                        :
                        null
                      }

                    </Col>
                  </Row>

                  {
                    this.state.loadLang ?
                      <div className="loader-wrap loader-wrap-account" >
                        <Player
                          autoplay={true}
                          loop={true}
                          src="/lf30_editor_l5cxzdyf.json"
                          style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                        ></Player>
                      </div>
                      :
                      null
                  }
                </div>
              </Col>
            </Row>


          </Container>



        </div>
      </div>

    );
  }
}

const selector = formValueSelector("modalForm");

export default connect(
  (state) => {
    return {
      selectedWaitingTimeType: selector(state, "selectedWaitingTimeType"),
    };
  },
  {
    changeMinValue: (value) => change("modalForm", "waitingMin", value),
    changeMaxValue: (value) => change("modalForm", "waitingMax", value),
    changeSelectedWaitingTimeType: (value) =>
      change("modalForm", "selectedWaitingTimeType", value),
  }
)(Page(Account));
