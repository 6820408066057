import React, { Component } from 'react';
import Page from '../../containers/page';
import { Player } from '@lottiefiles/react-lottie-player';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import ListBuilder from '../../components/listBuilder';
import Isvg from 'react-inlinesvg';
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg';
import garabage from '../../assets/svg/garbage.svg'
import DeleteModal from '../../components/deleteModal';
import FormBuilder from '../../components/forms/formBuilder';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class News extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
                "query-group": null,
            },
            loading: true,
            items: [],
            total: 0,
            showPlayer: false,
            showPlayer2: false
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

    }
    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/data/pages/news/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(parseJSON).then(({ result, status }) => {
                if (result.error) {
                    this.setState({ error: result.error })
                }
                this.get();
            })
        })

    }

    stopAnimation = () => {
        this.player.current.play();
    }

    // insertOrUpdate = (data) => {

    //     if (data && data._id) {

    //         fetch(API_ENDPOINT + '/data/pages/news/contact/' + data._id, {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                 'content-type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         }).then(res => res.json()).then((result) => {
    //             if (result.error) {
    //                 this.setState({
    //                     error: result.error,
    //                     showPlayer: false
    //                 })
    //             } else {
    //                 this.get();
    //                 this.setState({
    //                     message: result.message,
    //                     showPlayer: false,
    //                     showPlayer2: true,
    //                 },
    //                     () => {
    //                         setTimeout(() => {
    //                             this.setState({ showPlayer2: false });
    //                         }, 1000);
    //                     })
    //             }
    //         })


    //     } else {

    //         fetch(API_ENDPOINT + '/data/pages/news/contact/new', {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    //                 'content-type': 'application/json'
    //             },
    //             body: JSON.stringify(data)
    //         }).then(res => res.json()).then((result) => {
    //             if (result.error) {
    //                 this.setState({
    //                     error: result.error,
    //                 })
    //             } else {

    //                 this.get();
    //             }
    //         })

    //     }

    // }

    insertOrUpdate = (data) => {
        if (data && data._id) {
            this.setState(
                {
                    showPlayer: true,
                }, () => {
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/data/pages/news/contact/' + data._id, {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({
                                error: result.error,
                                showPlayer: false
                            })
                        } else {
                            this.setState({
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            this.get();
                        }
                    })
                }
            )
        } else {
            this.setState(
                {
                    showPlayer: true,
                }, () => {
                    fetch(API_ENDPOINT + '/data/pages/news/contact/new', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({
                                error: result.error,
                                showPlayer: false
                            })
                        } else {
                            this.setState({
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            this.get();
                        }
                    })

                })
        }
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        console.log(this.state.showPlayer, this.state.showPlayer2);
        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="add-button" onClick={() => {
                                        this.props[0].history.push('/pages/edit-news-page/' + 'new')
                                    }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'title', label: 'Name'.translate(this.props.lang), allowSort: true, multilang: true },

                                    ]}
                                    items={this.state.items.map(item => {
                                        return {
                                            ...item,
                                        }
                                    })}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => {
                                                    this.props[0].history.push('/pages/edit-news-page/' + item._id)
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>

                                <div>
                                    {
                                        // this.state.initialValues ?
                                        <FormBuilder dateFormat={this.props.dateFormat}
                                            onSubmit={(data) => {
                                                this.insertOrUpdate(data)
                                            }
                                            }
                                            initialValues={this.state.initialValues}
                                            buttonText={
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }
                                            lang={this.props.lang}
                                            fields={[
                                                {
                                                    type: 'row',
                                                    children: [

                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 12,
                                                                                sm: 12,
                                                                                xs: 12
                                                                            },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'title',
                                                                                    label: 'Title'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang
                                                                                },
                                                                                {
                                                                                    type: 'html',
                                                                                    name: 'shortDescription',
                                                                                    label: 'Short Description'.translate(this.props.lang),
                                                                                    className: 'estate-textarea-size',
                                                                                    multilang: true,
                                                                                    lang: this.props.lang
                                                                                },


                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        },
                                                    ]
                                                }
                                            ]}
                                        ></FormBuilder>
                                        // :
                                        // null
                                    }
                                </div>

                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete page'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.title[this.props.lang]}</strong>?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        );
    }
}

export default Page(News);