import React, { Component, useRef } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Isvg from 'react-inlinesvg'

import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

import moment from 'moment'
import logoBig from '../assets/svg/logo.svg'
import logoSmall from '../assets/svg/logo-small.svg'
import menuIcon from '../assets/svg/menu-icon.svg';
import logoMobile from '../assets/images/logo_mobile.png';
import homeIcon from '../assets/svg/sidebar-icons/home-icon.svg'
import dataIcon from '../assets/svg/sidebar-icons/data-icon.svg'
import managmentIcon from '../assets/svg/sidebar-icons/managment-icon.svg'
import userIcon from '../assets/svg/sidebar-icons/user-icon.svg'
import modulesIcon from '../assets/svg/sidebar-icons/modules-icon.svg'
import languagesIcon from '../assets/svg/sidebar-icons/languages-icon.svg'
import logoutIcon from '../assets/svg/sidebar-icons/logout-icon.svg'
import shapeBlue from '../assets/svg/shape-blue.svg'
import accountIcon from '../assets/svg/sidebar-icons/account.svg'
import clinicUsersIcon from '../assets/svg/sidebar-icons/clinics-users.svg'
import regionIcon from '../assets/svg/sidebar-icons/region-icon.svg'
import clinicIcon from '../assets/svg/sidebar-icons/clinic-icon.svg'
import profileUser from '../assets/images/user.png';


import { API_ENDPOINT } from '../constants'
moment.locale('sr')



function iosDevice() {
  var userAgent = window.navigator.userAgent;
  if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
    return true;
  }
  else {
    return false;
  }
}

export class Sidebar extends Component {
  constructor(props) {
    super(props)


    this.linkRefs = {};

    this.state = {
      showData: false,
      showManagment: false,
      selectedMobileItems: {},
    }
  }



  uDataCondition = (condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (this.props.uData[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  clinicEntryModuleCondition = (group, condition) => {
    let arr = condition.split('||');
    for (let i = 0; i < arr.length; i++) {
      if (group[arr[i]]) {
        return true;
      }
    }

    return false;
  }

  componentDidUpdate(prevProps) {
    if (this.linkRefs[this.props[0].location.pathname] && (this.props.sidebarScrollTop === null || this.props.sidebarScrollTop === undefined)) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);

      } else {
        this.sidebarNode.scrollTop = this.linkRefs[this.props[0].location.pathname].offsetTop;
        this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop);

      }

    }

  }

  componentDidMount() {
    console.log(this.props.sidebarScrollTop)
    if (this.props.sidebarScrollTop && this.sidebarNode) {
      if (window.location.href == `${window.location.origin}/` || window.location.href == `${window.location.origin}/my-data/account`) {
        this.sidebarNode.scrollTop = 0
      } else {
        this.sidebarNode.scrollTop = this.props.sidebarScrollTop;
      }


    } else {
      console.log(this.linkRefs)
    }
    console.log('mount')

  }


  render() {
    let doctorPermission;
    if (this.props.uData && this.props.uData.clinicModules && this.props.uData.clinicModules.length) {
      for (let i = 0; i < this.props.uData.clinicModules.length; i++) {
        if (this.props.uData.clinicModules[i].name == "Vårdutövare") {
          doctorPermission = this.props.uData.clinicModules[i]._id;
        }
      }
    }
    let isDoctor = false;
    if (this.props.uData.groupPermissionsUser && this.props.uData.groupPermissionsUser[this.props.selectedGroup] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissionsUser[this.props.selectedGroup][this.props.selectedClinic].indexOf(doctorPermission) != -1) {
      isDoctor = true;
    }


    let selectedGroupIdx = 0;
    if (this.props.selectedGroup && this.props.uData.clinicGroups) {
      for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
        if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
          selectedGroupIdx = i;
          break;
        }
      }
    }

    if (this.props.uData && this.props.uData.userLevel == 1) {
      return null;
    }

    if (this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type')
      return null;
    if (this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type')
      return null;
    if (this.props[0].match.path == '/video-call/:conversation')
      return null;


    let isMobile = typeof window != 'undefined' && window.innerWidth < 768;

    return (
      <div className={this.props.sidebarWide ? 'sidebar active' : 'sidebar'} style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}>
        <div className="sidebar-container">
          <div className='sidebar-content'>
            <div className='sidebar-header'>
              <Link to={this.props.modulesTree && this.props.modulesTree[0] ? this.props.modulesTree[0].link : '/'} className="logo">
                <img src={logoBig} className='logo' />
              </Link>
              {/* <Link to={this.props.modulesTree && this.props.modulesTree[0] ? this.props.modulesTree[0].link : '/'}>
                <img src={logoMobile} className='logo-small' />
              </Link> */}
              {/* <Isvg
                src={menuIcon}
                className='menu-icon'
                onClick={this.props.toggleSidebar}
              /> */}
              <div className="sidebar-profile">
                <Link to='/my-data/account'>
                  <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                </Link>
              </div>
            </div>


            <div className='sidebar-body' ref={(node) => this.sidebarNode = node} onScroll={() => this.props.updateSidebarScrollTop(this.sidebarNode.scrollTop)}>

              <ul className='menu'>

                {
                  this.props.modulesTree.map((item, idx) => {


                    let subpaths = [];
                    for (let i = 0; i < item.modules.length; i++) {
                      let sub = item.modules[i];
                      if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub._id) !== -1) &&
                        (!sub.clinicEntryModuleCondition ||
                          (
                            sub.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                          )
                        )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                        if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1)) {
                          subpaths.push(sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link);
                        }
                      }
                    }
                    if ((!item.clinicEntryModule ||
                      (
                        item.clinicEntryModule &&
                        this.props.uData &&
                        this.props.uData.groupPermissions &&
                        this.props.uData.groupPermissions[this.props.selectedGroup] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] &&
                        this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(item._id) !== -1 &&
                        (!item.clinicEntryModuleCondition ||
                          (
                            item.clinicEntryModuleCondition &&
                            this.props.uData &&
                            this.props.uData.clinicGroups &&
                            this.props.uData.clinicGroups[selectedGroupIdx] &&
                            this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], item.clinicEntryModuleCondition)
                          )
                        )
                      )) && (!item.uDataCondition || (item.uDataCondition && this.props.uData && this.uDataCondition(item.uDataCondition)))
                    )
                      return (
                        <li key={item.id} ref={(node) => this.linkRefs[subpaths.length ? subpaths[0] : item.link] = node} className={(((subpaths.length != 0 && subpaths.indexOf(this.props[0].location.pathname) !== -1) || (subpaths.length == 0 && item.link == this.props[0].location.pathname)) && !isMobile) || (this.state.selectedMobileItems[item._id] && isMobile) ? 'menu__item active' : 'menu__item'}>
                          <Link className='menu__link' onClick={(e) => {
                            this.props.registerLog(`Open module: ${item.name.en} / ${item.modules && item.modules[0] && item.modules[0].name ? item.modules[0].name.en : '-'}`);
                            if (isMobile && subpaths.length != 0) {
                              let selectedMobileItems = this.state.selectedMobileItems;
                              selectedMobileItems[item._id] = !selectedMobileItems[item._id];
                              this.setState({ selectedMobileItems });
                              e.preventDefault();

                              return false;
                            }
                          }} to={subpaths.length ? subpaths[0] : item.link}>
                            <span>
                              {' '}
                              <Isvg src={API_ENDPOINT + item.svgIcon} className='header-icon' />{' '}
                            </span>
                            <span className="menu__text">{Object.translate(item, 'name', this.props.lang)}</span>
                          </Link>
                          {item.modules && item.modules.length ?
                            <ul className={(subpaths.indexOf(this.props[0].location.pathname) !== -1 && !isMobile) || (this.state.selectedMobileItems[item._id] && isMobile) ? 'dropdown show' : 'dropdown'}>
                              {
                                item.modules.map((sub, sidx) => {
                                  if ((!sub.clinicEntryModule || (sub.clinicEntryModule && this.props.uData && this.props.uData.groupPermissions && this.props.uData.groupPermissions[this.props.selectedGroup] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic] && this.props.uData.groupPermissions[this.props.selectedGroup][this.props.selectedClinic].indexOf(sub._id) !== -1) &&
                                    (!sub.clinicEntryModuleCondition ||
                                      (
                                        sub.clinicEntryModuleCondition &&
                                        this.props.uData &&
                                        this.props.uData.clinicGroups &&
                                        this.props.uData.clinicGroups[selectedGroupIdx] &&
                                        this.clinicEntryModuleCondition(this.props.uData.clinicGroups[selectedGroupIdx], sub.clinicEntryModuleCondition)
                                      )
                                    )) && (!sub.uDataCondition || (sub.uDataCondition && this.props.uData && this.uDataCondition(sub.uDataCondition)))) {

                                    if ((sub.link && sub.link.indexOf(':alias') !== -1 && localStorage.getItem('groupAlias')) || (sub.link && sub.link.indexOf(':alias') == -1))

                                      return (
                                        <li className={sub.link == this.props[0].location.pathname ? 'active' : ''} onClick={() => {
                                          this.props.registerLog(`Open module: ${item.name.en} / ${sub.name.en}`);

                                        }}> <Link to={sub.link && sub.link.indexOf(':alias') !== -1 ? sub.link.replace(':alias', localStorage.getItem('groupAlias')) : sub.link}>
                                            <span>
                                              {' '}
                                              <Isvg src={API_ENDPOINT + sub.svgIcon} className='header-icon' />{' '}
                                            </span>
                                            <span className="menu__text">{Object.translate(sub, 'name', this.props.lang)}</span>
                                          </Link> </li>

                                      )
                                  }
                                })
                              }

                            </ul>
                            :
                            null
                          }
                        </li>

                      )
                  })
                }


                <li className='menu__item'>
                  <div onClick={() => {
                    this.props.registerLog('Logged out');

                    this.props.signOut()
                    

                  }} className='menu__link'>
                    <span>
                      {' '}
                      <Isvg src={logoutIcon} className='header-icon' />{' '}
                    </span>
                    <span className='menu__text' > {'Logout'.translate(this.props.lang)} </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* <img src={shapeBlue} className='shape-sidebar' /> */}
        </div>
      </div >
    )
  }
}

export default Sidebar
