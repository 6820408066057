import React, { Component } from 'react';
import { API_ENDPOINT } from '../constants';
/**
* Payson transaction check component 
* @author   Stefan Pantic
*/
class Transaction extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        let key = this.props[0].match.params.key;
        let id = this.props[0].match.params.id;

        fetch(API_ENDPOINT + '/users/account/verify', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ key, id })
        }).then(res => res.json()).then((result) => {
            if (result.error) {
                this.setState({
                    error: result.error
                })
            } 

        });
    }


    render() {
        // console.log(this.props)
        return (
            <h1>
                Verification success
            
            </h1>
        );
    }
}

export default Transaction;