import React from 'react'
import reactCSS from 'reactcss'
import {
    Container,
    Row,
    Col,
    FormGroup, Label
} from 'reactstrap';
import { SketchPicker } from 'react-color'


/**
* Custom colorpicker
* @author   Stefan Pantic
*/
class SketchExample extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false,
            color: {
                r: '255',
                g: '0',
                b: '0',
                a: '1',
            },
        };
    }


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    handleChange = (color) => {
        this.props.onChange(
            color.hex
        ),
        this.setState({
            color: color.rgb
        })

    };

    render() {

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    background: this.props.value,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                    position: 'relative',
                    top: '15px',
                },
                popover: {
                    position: 'absolute',
                    zIndex: '2',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <FormGroup>
                {this.props.label ? <Label>{this.props.label}</Label> : null}

                <div>

                    <div style={styles.swatch} onClick={this.handleClick}>
                        <div style={styles.color} />
                    </div>
                    {this.state.displayColorPicker && !this.props.disabled ? <div style={styles.popover}>
                        <div style={styles.cover} onClick={this.handleClose} />
                        <SketchPicker color={this.props.value} onChange={this.handleChange} />
                    </div> : null}

                </div>
            </FormGroup>

        )
    }
}

export default SketchExample