import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';

import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import Search from '../../components/search';
import addIcon from '../../assets/svg/add-icon.svg';

import profileUser from '../../assets/images/user.png';
import notification from '../../assets/svg/notification.svg'
import moreIcon from '../../assets/svg/more.svg'

import { Player, Controls } from '@lottiefiles/react-lottie-player';


/**
* Translations page
* @author   Milan Stanojevic
*/
class Translation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update translation
    * @author   Milan Stanojevic
    * @Objectparam    {String} key                 key of translation
    * @Objectparam    {String} en                  english translation
    * @Objectparam    {String} se                  swedish translation
    */
    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/languages/translation/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/languages/translation/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }

    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete translation
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                  translation's id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/languages/translation/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'key', label: 'Key'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'en', label: 'English'.translate(this.props.lang), allowSort: true },
                                        { type: 'text', name: 'se', label: 'Swedish'.translate(this.props.lang), allowSort: true },
                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete translation'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder dateFormat={this.props.dateFormat}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit translation'.translate(this.props.lang) : 'Add new translation'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}

                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'key',
                                                disabled: false,
                                                label: 'Key'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'en',
                                                label: 'English'.translate(this.props.lang),
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'se',
                                                label: 'Swedish'.translate(this.props.lang),
                                            },
                                        ]
                                    },

                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}

export default Page(Translation);