import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

/**
* Generate name for sent sms messages
* @author   Milan Stanojevic
*/
class SmsName extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        return (
            <FormGroup>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <Input maxLength="11" size="sm" disabled={this.props.disabled} name={this.props.name} invalid={this.props.error ? true : false} onChange={(e) => {
                    let value = e.target.value.replace(/Å/g, 'A').replace(/Ä/g, 'A').replace(/Ö/g, 'O').replace(/å/g, 'a').replace(/ä/g, 'a').replace(/ö/g, 'o').replace(/[^a-zA-Z0-9]/gi, '-').replace(/-+/g, '');
                    if (value.length > 11) {
                        return;
                    }
                    this.props.onChange(value);

                }} value={this.props.value} placeholder={this.props.placeholder}></Input>
                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default SmsName;