
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col, Form, Table } from 'reactstrap';

/**
* Table component
* @author   Milan Stanojevic
*/
class TableField extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        if (!this.props.value || (this.props.value && !this.props.value.length)){
            return null;
        }
        return (
            <FormGroup className="form-table-group">
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}

                <Table striped className="table-field">
                    <tbody>
                        {
                            this.props.value && this.props.value.map((item, idx) => {
                                return (
                                    <tr key={idx} onClick={() => {
                                        if (!this.props.actions && this.props.onClick) {
                                            this.props.onClick(item)
                                        }
                                    }}>
                                        {
                                            this.props.fields.map((field, fidx) => {
                                                if (field.type == 'two-rows-text') {
                                                    return (
                                                        <td key={fidx}>
                                                            {
                                                                field.strong && field.strong(item) ?
                                                                    <>
                                                                        <strong> {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''}  {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)}</strong> <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''} {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)} <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>

                                                                    </>
                                                            }

                                                        </td>
                                                    )
                                                } else if (field.type == 'text') {
                                                    return (
                                                        <td key={fidx}>{field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]}</td>
                                                    )
                                                } else if (field.type == 'list') {
                                                    return (
                                                        <td key={fidx}>
                                                            {
                                                                item[field.name].map((fitem, fitemidx) => {
                                                                    return (
                                                                        <div className="list-item">
                                                                            {fitem[field.itemName]}

                                                                            {
                                                                                field.actions.map((action, aidx) => {
                                                                                    return (
                                                                                        <button key={aidx} onClick={() => action.onClick(item, fitemidx)}>{action.component}</button>
                                                                                    )
                                                                                })

                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                    )

                                                }
                                            })
                                        }
                                        {this.props.actions ?
                                            <td className="action-td">
                                                {
                                                    this.props.actions && this.props.actions.length ?
                                                        this.props.actions.map((action, aidx) => {
                                                            if (!action.condition || (action.condition && action.condition(item)))
                                                                return (
                                                                    <button type="button" key={aidx} onClick={() => {
                                                                        action.onClick(item)

                                                                    }}>{action.component}</button>
                                                                )
                                                        })
                                                        :
                                                        null
                                                }
                                            </td>
                                            :
                                            null
                                        }

                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </Table>
            </FormGroup>

        )

    }
}

export default TableField;