
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import {
    FormGroup, Label, Form
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import image from '../../../assets/svg/picture.svg';
import { API_ENDPOINT } from '../../../constants';
import { Player, Controls } from "@lottiefiles/react-lottie-player";


function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}
/**
* Component for import and style image
* @author   Milan Stanojevic
*/
class Image extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        //console.log('select file')
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {
            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))

            if(file > 5120){
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            }else{
                this.setState({
                    _loading: true
                })
    
                let formData = new FormData();
                formData.append('file', input.files[0]);
    
                fetch(API_ENDPOINT + '/data/upload-image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
    
                    },
                    body: formData
                }).then(parseText).then(({ result, status }) => {
                    if (status != 200) {
                        this.setState({ error: result.translate(this.props.lang) })
                    } else {
                        this.props.onChange(result);
                        this.setState({
                            _loading: null
                        })
                    }
                })
            }

            
        }
    }

    render() {
        return (
            <FormGroup className="image-picker-wrap">
                <Label size="sm">{this.props.label}</Label>

                <div className="image-picker" style={{ position: 'relative'}}>
                    <input type="file" accept=".jpg, .jpeg, .JPG, .JPEG, .png, .gif" onChange={this.selectFile} key={this.props.value} />
                    {this.props.value ?
                        <img src={API_ENDPOINT + this.props.value} />
                        :
                        null
                    }
                    {
                        this.state._loading ?
                            <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: "128px", width: "128px", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)' }}
                            ></Player>

                        </div>
                            :
                            null
                    }

                    {
                        this.props.value ?
                            <button className="delete" onClick={() => this.props.onChange(null)}>&times;</button>
                            :
                            null
                    }

                    <div className="bottom-content">
                        <Isvg src={image} />
                        <p> <span>{'Upload a file'.translate(this.props.lang && this.props.lang)}</span> {'or drag and drop'.translate(this.props.lang)}</p>
                    </div>


                </div>
                <div>{this.state.error}</div>
            </FormGroup>


        );
    }
}

export default Image;