import React, { Component } from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom'
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import info from '../assets/svg/info.svg'
import profileUser from '../assets/images/user.png';
import { API_ENDPOINT } from '../constants';


/**
* Checks if there is a new notification
* @author Stefan Pantic
*/

import {
    Container,
    Row,
    Col, Input, Label, Modal, ModalBody, ModalHeader, FormGroup, Button
} from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';

export class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        this.interval = setInterval(() => {
            this.check();
        }, 5000);

    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    /**
    * Checks if there is a new notification for logged user
    * @author   Stefan pantic
    */
    check = () => {
        fetch(API_ENDPOINT + '/notification/last', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {

            if (result) {
                if (localStorage.getItem('notif') != result._id) {
                    this.setState({
                        newNotif: true
                    })

                } else {
                    this.setState({
                        newNotif: false
                    })
                }
            }

        })


    }


    render() {
        if (this.props[0].match.path == '/:alias' || this.props[0].match.path == '/video-call/:conversation' || this.props[0].match.path == '/:alias/login' || this.props[0].match.path == '/my-data/clinic-data/landing-configurator/:id/:tab/:group/:type' || this.props[0].match.path == '/data-managment/landing-configurator/:tab/:id/:type' || this.props[0].match.path == '/:alias/information' || !this.props.uData || this.props[0].match.path == '/:alias/privacy-policy' || this.props[0].match.path == '/:alias/terms-of-use' || this.props[0].match.path == '/:alias/accessibility-report') {
            return (
                <>
                    {
                        this.props.logoutMessage ?
                            <Modal isOpen={this.props.logoutMessage} centered >
                                <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader>

                                <ModalBody className='delete-modal'>
                                    <img src={info} />
                                    <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color="primary" style={{ float: 'right' }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                                </ModalFooter>
                            </Modal>

                            :
                            null
                    }
                </>
            );
        }
        return (
            <div className='component notifications'>
                <div className='profile'>
                    <Link to="/my-data/account">
                        <span className='name'>{this.props.uData ? this.props.uData.userData.name + " " + (this.props.uData.userData.title && typeof localStorage && localStorage.getItem('profile') != 'patient' ? this.props.uData.userData.title : '') : null}</span>
                        <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profileUser} className="profile-image" />
                        {/* <Isvg src={moreIcon} /> */}
                    </Link>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 15 }}>
                        <label>{'Language:'.translate(this.props.lang)}</label>
                    </div>
                    <div className="language" style={{ minWidth: 150 }}>
                        <select
                            value={this.props.lang}
                            onChange={(e) => {
                                this.props.setLang(e.target.value);
                            }}
                        >
                            <option value="se">Svenska</option>
                            <option value="en">English</option>
                        </select>
                    </div>
                </div>
                {
                    this.props.logoutMessage ?
                        <Modal isOpen={this.props.logoutMessage} centered >
                            <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className='delete-modal'>
                                <img src={info} />
                                <p className='message'>{'You have been logged out due to inactivity.'.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ float: 'right' }} onClick={() => this.props.disableLogoutMessage()}>{"Ok".translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        :
                        null
                }
            </div>
        )
    }
}

export default Notification;