import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col

} from 'reactstrap';

import FormBuilder from '../../components/forms/formBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Checkbox from '../../components/forms/fields/checkbox';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class EditWeassistPageForm extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            madiaLinks: [],
            loading: true,
            initialValues: false
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        if (this.props[0].match.params.id) {
            fetch(API_ENDPOINT + '/data/pages/weassist/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                console.log('ovo je result:', result);
                if (result.error) {
                    this.setState({
                        error: result.error,
                    })
                } else {
                    console.log('get vratio  ', result);
                    this.setState({
                        initialValues: { ...result },
                    })
                }
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    insertOrUpdate = (data) => {
        this.setState(
            {
                showPlayer: true,
            }, () => {
                this.player.current.play();
                fetch(API_ENDPOINT + '/data/pages/weassist/' + data._id, {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result.error) {
                        this.setState({
                            error: result.error,
                            showPlayer: false
                        })
                    } else {
                        this.setState({
                            message: result.message,
                            showPlayer: false,
                            showPlayer2: true,
                            initialValues: false
                        }, () => {
                            setTimeout(() => {
                                this.setState({ showPlayer2: false });
                            }, 1000);
                        })
                        this.get();
                    }
                })
            }
        )
    }


    render() {

        return (
            <div className="dashboard" >
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Checkbox label={'Section 1'} checked={this.state.initialValues && this.state.initialValues.section1} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section1 = this.state.initialValues.section1 ? !this.state.initialValues.section1 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 2'} checked={this.state.initialValues && this.state.initialValues.section2} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section2 = this.state.initialValues.section2 ? !this.state.initialValues.section2 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 3'} checked={this.state.initialValues && this.state.initialValues.section3} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section3 = this.state.initialValues.section3 ? !this.state.initialValues.section3 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 4'} checked={this.state.initialValues && this.state.initialValues.section4} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section4 = this.state.initialValues.section4 ? !this.state.initialValues.section4 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 5'} checked={this.state.initialValues && this.state.initialValues.section5} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section5 = this.state.initialValues.section5 ? !this.state.initialValues.section5 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 6'} checked={this.state.initialValues && this.state.initialValues.section6} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section6 = this.state.initialValues.section6 ? !this.state.initialValues.section6 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 7'} checked={this.state.initialValues && this.state.initialValues.section7} onChange={(e) => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section7 = this.state.initialValues.section7 ? !this.state.initialValues.section7 : true
                                        this.setState({
                                            initialValues
                                        })
                                    }} />
                                    <Checkbox label={'Section 8'} checked={this.state.initialValues && this.state.initialValues.section8} onChange={() => {
                                        let initialValues = this.state.initialValues
                                        initialValues.section8 = this.state.initialValues.section8 ? !this.state.initialValues.section8 : true
                                        this.setState({
                                            initialValues
                                        }, () => {
                                            console.log('1111', this.state.initialValues.section8);
                                        })
                                    }} />

                                </div>
                                {
                                    this.state.initialValues ?
                                        <FormBuilder dateFormat={this.props.dateFormat}
                                            onSubmit={(data) => {
                                                this.insertOrUpdate(data)
                                            }}
                                            initialValues={this.state.initialValues}
                                            buttonText={
                                                this.state.showPlayer ? (
                                                    <Player
                                                        onEvent={(event) => {
                                                            if (event === "load") this.stopAnimation();
                                                        }}
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>

                                                ) : this.state.showPlayer2 ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                        onStateChange={this.toggleVisibility}
                                                        ref={this.player2} // set the ref to your class instance
                                                        autoplay={true}
                                                        loop={true}
                                                        controls={true}
                                                        src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                        style={{ height: "30px", width: "30px" }}
                                                    ></Player>
                                                        {'Saved'.translate(this.props.lang)}
                                                    </div>
                                                ) : (
                                                    "Save".translate(this.props.lang)
                                                )
                                            }
                                            lang={this.props.lang}
                                            fields={[
                                                {
                                                    type: 'row',
                                                    children: [
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'h4',
                                                                    text: 'Seo tag'
                                                                },
                                                                {
                                                                    type: 'text',
                                                                    name: 'seotag',
                                                                    label: 'Title'.translate(this.props.lang),
                                                                    multilang: true,
                                                                    lang: this.props.lang,
                                                                    // validate: [required('Title is required!'.translate(this.props.lang))]
                                                                },
                                                                {
                                                                    type: 'html',
                                                                    name: 'seoDescription',
                                                                    label: 'description'.translate(this.props.lang),
                                                                    multilang: true,
                                                                    lang: this.props.lang,
                                                                    // validate: [required('description is required!'.translate(this.props.lang))]
                                                                },
                                                            ]
                                                        },
                                                        {
                                                            type: 'col',
                                                            width: {
                                                                lg: 12,
                                                                sm: 12,
                                                                xs: 12
                                                            },
                                                            children: [
                                                                {
                                                                    type: 'row',
                                                                    children: [
                                                                        {
                                                                            type: 'col',
                                                                            width: {
                                                                                lg: 12,
                                                                                sm: 12,
                                                                                xs: 12
                                                                            },
                                                                            children: [
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'name',
                                                                                    label: 'Name'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'text',
                                                                                    name: 'alias',
                                                                                    label: 'Alias'.translate(this.props.lang),
                                                                                    multilang: true,
                                                                                    lang: this.props.lang,
                                                                                    //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                },
                                                                                {
                                                                                    type: 'checkbox',
                                                                                    name: 'backgroundImagePositionLeft',
                                                                                    afterText: 'Background Image Position Left'.translate(this.props.lang),
                                                                                },
                                                                                {
                                                                                    type: 'checkbox',
                                                                                    name: 'backgroundImagePositionRight',
                                                                                    afterText: 'Background Image Position Right'.translate(this.props.lang),
                                                                                },
                                                                            ]
                                                                        }
                                                                    ]
                                                                },

                                                                this.state.initialValues && this.state.initialValues.section1 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [

                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 1'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [

                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section2 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 2'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[1].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[1].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: "image",
                                                                                        name: "section[1].item[0].clinicLogo",
                                                                                        label: "Clinic Logo".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[0].clientText",
                                                                                        label: "Client Text".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[0].clientName",
                                                                                        label: "Client Name".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[0].clientPosition",
                                                                                        label: "Client Position".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[1].item[0].backgroundLightGrey',
                                                                                        afterText: 'Section 2 background light grey'.translate(this.props.lang),
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[1].item[0].backgroundDark',
                                                                                        afterText: 'Section 2 background dark'.translate(this.props.lang),
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[1].item[1].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[1].item[1].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[1].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: "image",
                                                                                        name: "section[1].item[1].clinicLogo",
                                                                                        label: "Clinic Logo".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[1].clientText",
                                                                                        label: "Client Text".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[1].clientName",
                                                                                        label: "Client Name".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[1].item[1].clientPosition",
                                                                                        label: "Client Position".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section3 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 3'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[2].item[0].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[2].item[0].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[2].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: "image",
                                                                                        name: "section[2].item[0].clinicLogo",
                                                                                        label: "Clinic Logo".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[2].item[0].clientText",
                                                                                        label: "Client Text".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[2].item[0].clientName",
                                                                                        label: "Client Name".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[2].item[0].clientPosition",
                                                                                        label: "Client Position".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[2].item[0].backgroundDark',
                                                                                        afterText: 'Section 2 background dark'.translate(this.props.lang),
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[2].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[2].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section4 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 4'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[3].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[3].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[3].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: "image",
                                                                                        name: "section[3].item[2].clinicLogo",
                                                                                        label: "Clinic Logo".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[3].item[3].clientText",
                                                                                        label: "Client Text".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[3].item[4].clientName",
                                                                                        label: "Client Name".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: "text",
                                                                                        name: "section[3].item[5].clientPosition",
                                                                                        label: "Client Position".translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section5 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 5'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[4].item[1].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [

                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[4].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[4].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,

                                                                this.state.initialValues && this.state.initialValues.section6 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 6'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[5].item[0].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[5].item[0].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },

                                                                        ]
                                                                    } : null,

                                                                this.state.initialValues && this.state.initialValues.section7 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 7'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[6].item[0].image.se',
                                                                                        label: 'Image SE'.translate(this.props.lang),

                                                                                    },
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[6].item[0].image.en',
                                                                                        label: 'Image EN'.translate(this.props.lang),

                                                                                    },
                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[6].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[6].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },

                                                                                ]
                                                                            },
                                                                        ]
                                                                    } : null,
                                                                this.state.initialValues && this.state.initialValues.section8 ?
                                                                    {
                                                                        type: 'row',
                                                                        children: [
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 12,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'h4',
                                                                                        text: 'section 8'
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'text',
                                                                                        name: 'section[7].item[1].title',
                                                                                        label: 'Title'.translate(this.props.lang),
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                        //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                                    },
                                                                                    {
                                                                                        type: 'html',
                                                                                        name: 'section[7].item[1].shortDescription',
                                                                                        label: 'Short Description'.translate(this.props.lang),
                                                                                        height: 250,
                                                                                        className: 'estate-textarea-size',
                                                                                        multilang: true,
                                                                                        lang: this.props.lang,
                                                                                    },
                                                                                    {
                                                                                        type: 'checkbox',
                                                                                        name: 'section[7].item[1].sweeden',
                                                                                        afterText: 'Only sweeden'.translate(this.props.lang),
                                                                                    },

                                                                                ]
                                                                            },
                                                                            {
                                                                                type: 'col',
                                                                                width: {
                                                                                    lg: 6,
                                                                                    sm: 12,
                                                                                    xs: 12
                                                                                },
                                                                                children: [
                                                                                    {
                                                                                        type: 'image',
                                                                                        name: 'section[7].item[0].image',
                                                                                        label: 'Image'.translate(this.props.lang),

                                                                                    },
                                                                                ]
                                                                            },


                                                                        ]
                                                                    } : null,
                                                            ]
                                                        },
                                                    ]
                                                }
                                            ]}
                                        ></FormBuilder>
                                        :
                                        null
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(EditWeassistPageForm);