import React, { Component } from 'react';
import moment from 'moment';
import Page from '../../containers/page';
import ListBuilder from '../../components/listBuilder';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { getSearchParams  } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';


var striptags = require('striptags');

/**
* Notification page
* @author   Stefan Pantic
*/
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promotedProjects: [],
            ...props.initialData,
            form: false,

            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: []
        };
    }
    /**
    * Function call api for last notification to check if notification is needed and then updated list of notificatons with verifyUser() function
    * @author   Stefan Pantic
    */
    get = () => {
        fetch(API_ENDPOINT + '/notification/last', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {
            console.log(result)
            localStorage.setItem('notif', result._id);

        })

        this.props.verifyUser()
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, sortField: 'timestamp', sortType: -1, user: this.props.uData._id })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();


        this.interval = setInterval(() => {
            this.get();
        }, 60 * 1000);

    }
    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }




    /**
    * Change group if needed 
    * @author   Stefan Pantic
    * @Objectparam    {Object} data    notification data
    */
    setGroup = (data) => {

        if (data.type.indexOf('|') != -1 && this.props.uData.userLevel != 1 && this.props.uData.userLevel != 2) {
            let groupId = data.type.split('|');
            if (groupId[1] && this.props.selectedGroup.indexOf(groupId[1]) == -1) {
                for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                    if (this.props.uData.clinicGroups[i]._id.indexOf(groupId[1]) != -1) {
                        this.props.changeGroup(groupId[1])
                    }
                }
            }
        }
    }

    render() {
        let params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        //console.log(params);
        return (
            <div className="dashboard">
                <Container fluid>
                    <div className="panel select-clinics-wrapper">
                        <h5 className="component-header mb-35">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                        <Row>
                            <Col lg="12">
                                <div className="choose-clinic-notification">
                                    {this.props.uData.lastNotifications.map(item => {
                                        if (item.message.indexOf('|') != -1) {
                                            let messageForTranslate = item.message.split('|')
                                            let message = ''
                                            if (messageForTranslate[0]) {
                                                message = message + messageForTranslate[0].translate(this.props.lang)
                                            }
                                            if (messageForTranslate[1]) {
                                                message = message + messageForTranslate[1]
                                            }
                                            if (messageForTranslate[2]) {
                                                message = message + messageForTranslate[2].translate(this.props.lang)
                                            }
                                            if (messageForTranslate[3]) {
                                                message = message + messageForTranslate[3]
                                            }

                                            item.message = message;
                                        }
                                        if(item.description.indexOf('|') != -1){
                                            let messageForTranslate = item.description.split('|')
                                            let message = ''
                                            if (messageForTranslate[0]) {
                                                message = message + messageForTranslate[0].translate(this.props.lang)
                                            }
                                            if (messageForTranslate[1]) {
                                                message = message + messageForTranslate[1]
                                            }
                                            item.description = message;
                                        }
                                        return (
                                            <div style={{ position: 'relative' }}>
                                                {item.type === 'message' ?
                                                    <div onClick={() => {
                                                        this.setGroup(item)
                                                        fetch(API_ENDPOINT + '/notification/seen/update/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify()
                                                        }).then(res => res.json()).then((result) => {
                                                            this.get();

                                                        })
                                                    }}>
                                                        <a href={item.link}>
                                                            <div>
                                                                <img src={API_ENDPOINT + item.logo} />
                                                                <div>
                                                                    <h6>{item.message.translate(this.props.lang)}</h6>
                                                                    <p>{item.description}</p>
                                                                    <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>

                                                    :
                                                    <Link onClick={() => {
                                                        this.setGroup(item)
                                                        fetch(API_ENDPOINT + '/notification/seen/update/' + item._id, {
                                                            method: 'POST',
                                                            headers: {
                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                'content-type': 'application/json'
                                                            },
                                                            body: JSON.stringify()
                                                        }).then(res => res.json()).then((result) => {
                                                            this.get();
                                                            if (item.link == "/my-data/personal-referrals/referrals") {
                                                                localStorage.setItem('profile', 'patient')
                                                                localStorage.setItem('userLevel', 2);
                                                            } else if (item.link == '/my-data/personal-referrals/received') {
                                                                localStorage.setItem('profile', 'doctor');
                                                                localStorage.setItem('userLevel', 20);
                                                            }
                                                        })
                                                    }} to={{
                                                        pathname: item.link,
                                                        state: {
                                                            messageModal: item.check
                                                        }
                                                    }}>
                                                        <div>
                                                            <img src={API_ENDPOINT + item.logo} />
                                                            <div>
                                                                <h6>{item.message.translate(this.props.lang)}</h6>
                                                                <p>{item.description}</p>
                                                                <p>{moment.unix(item.timestemp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                            </div>
                                                        </div>

                                                    </Link>

                                                }

                                                <div className={item.seen ? '' : 'ticket-notification'}></div>
                                            </div>


                                        )

                                    })
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

        );


    }
}

export default Page(HomePage);