import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col

} from 'reactstrap';

import FormBuilder from '../../components/forms/formBuilder';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { required } from '../../components/forms/validation';

import Checkbox from '../../components/forms/fields/checkbox';

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

class EditArticlesPage extends Component {
    constructor(props) {
        super(props);
        this.player = React.createRef();
        this.player2 = React.createRef();
        this.state = {
            ...props.initialData,
            form: false,
            madiaLinks: [],
            loading: true,
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, 'query-group': null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        if (this.props[0].match.params.id && this.props[0].match.params.id !== 'new') {
            fetch(API_ENDPOINT + '/data/pages/articles/' + this.props[0].match.params.id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({})
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                    })
                } else {
                    this.setState({
                        initialValues: result,
                    })
                }
            })
        } else {
            this.setState({
                initialValues: {}
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    stopAnimation = () => {
        this.player.current.play();
    }

    insertOrUpdate = (data) => {
        if (data && data._id) {
            this.setState(
                {
                    showPlayer: true,
                }, () => {
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/data/pages/articles/' + data._id, {
                        method: 'PUT',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({
                                error: result.error,
                                showPlayer: false
                            })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            this.get();
                        }
                    })
                }
            )
        } else {
            this.setState(
                {
                    showPlayer: true,
                }, () => {
                    this.player.current.play();
                    fetch(API_ENDPOINT + '/data/pages/articles/new', {
                        method: 'POST',
                        headers: {
                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    }).then(res => res.json()).then((result) => {
                        if (result.error) {
                            this.setState({
                                error: result.error,
                                showPlayer: false
                            })
                        } else {
                            this.setState({
                                message: result.message,
                                showPlayer: false,
                                showPlayer2: true,
                            },
                                () => {
                                    setTimeout(() => {
                                        this.setState({ showPlayer2: false });
                                    }, 1000);
                                })
                            this.get();
                        }
                    })
                }
            )
        }

    }


    render() {

        return (
            <div className="dashboard" >
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">


                                <FormBuilder dateFormat={this.props.dateFormat}
                                    onSubmit={(data) => {
                                        this.insertOrUpdate(data)
                                    }
                                    }
                                    initialValues={this.state.initialValues}
                                    buttonText={
                                        this.state.showPlayer ? (
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load") this.stopAnimation();
                                                }}
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>

                                        ) : this.state.showPlayer2 ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}><Player
                                                onStateChange={this.toggleVisibility}
                                                ref={this.player2} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="https://assets10.lottiefiles.com/packages/lf20_CxyxLk.json"
                                                style={{ height: "30px", width: "30px" }}
                                            ></Player>
                                                {'Saved'.translate(this.props.lang)}
                                            </div>
                                        ) : (
                                            "Save".translate(this.props.lang)
                                        )
                                    }
                                    lang={this.props.lang}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    
                                                    

                                                    
                                                    children: [
                                                        {
                                                            type: 'h4',
                                                            text: 'Seo Tags'
                                                        },
                                                        {
                                                            type: 'text',
                                                            name: 'titleSeoArticle',
                                                            label: 'Title'.translate(this.props.lang),
                                                            multilang: true,
                                                            lang: this.props.lang,
                                                            // validate: [required('Address is required!'.translate(this.props.lang))]
                                                        },
                                                        {
                                                            type: 'html',
                                                            name: 'descriptionSeoArticle',
                                                            label: 'Description'.translate(this.props.lang),
                                                            multilang: true,
                                                            lang: this.props.lang,
                                                            // validate: [required('Address is required!'.translate(this.props.lang))]
                                                        },

                                                    ]
                                                },

                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                    
                                                        {
                                                            type: 'row',
                                                            children: [
                                                                {
                                                                    type: 'h4',
                                                                    text: 'Article Details'
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: {
                                                                        lg: 12,
                                                                        sm: 12,
                                                                        xs: 12
                                                                    },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'address',
                                                                            label: 'Address'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang,
                                                                            validate: [required('Address is required!'.translate(this.props.lang))]
                                                                        },

                                                                        {
                                                                            type: 'text',
                                                                            name: 'title',
                                                                            label: 'Title'.translate(this.props.lang),
                                                                            multilang: true,
                                                                            lang: this.props.lang,
                                                                            //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                        },
                                                                        {
                                                                            type: 'text',
                                                                            name: 'date',
                                                                            label: 'Date for article'.translate(this.props.lang),
                                                                            // multilang: true,
                                                                            // lang: this.props.lang,
                                                                            //validate: [required('Title is required!'.translate(this.props.lang))]
                                                                        },
                                                                        {
                                                                            type: 'html',
                                                                            name: 'shortDescriptionPage',
                                                                            label: 'Subtitle on collection page'.translate(this.props.lang),
                                                                            height: 250,
                                                                            className: 'estate-textarea-size',
                                                                            multilang: true,
                                                                            lang: this.props.lang,
                                                                        },
                                                                        {
                                                                            type: 'html',
                                                                            name: 'shortDescription',
                                                                            label: 'Short Description Details'.translate(this.props.lang),
                                                                            height: 250,
                                                                            className: 'estate-textarea-size',
                                                                            multilang: true,
                                                                            lang: this.props.lang,
                                                                        },
                                                                        {
                                                                            type: 'image',
                                                                            name: 'backgroundImage',
                                                                            label: 'Background image'.translate(this.props.lang),

                                                                        },
                                                                        {
                                                                            type: 'html',
                                                                            name: 'description',
                                                                            label: 'Description'.translate(this.props.lang),
                                                                            height: 250,
                                                                            className: 'estate-textarea-size',
                                                                            multilang: true,
                                                                            lang: this.props.lang,
                                                                        },

                                                                    ]
                                                                },
                                                            ]
                                                        },

                                                    ]
                                                },
                                            ]
                                        }
                                    ]}
                                ></FormBuilder>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Page(EditArticlesPage);