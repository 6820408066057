import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Notification from '../components/notifications';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';

function iosDevice() {
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


export const DefaultLayout = (Wrapped) => (props) => {
    return (
        <div style={typeof window != 'undefined' && iosDevice()  ? { maxWidth: `${window.innerWidth}px` } : {}} className={props.sidebarWide ? `home-content home-content-sidebar-wide ${props.uData ? 'logged-in' : ''}` : `home-content home-content-sidebar-short ${props.uData ? '' : 'not-logged-in'}`}>
            {props.uData ? <Sidebar className="sidebar" {...props} /> : null}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Notification {...props} />
                <Wrapped {...props} />
            </div>

        </div>
    );
};

export default DefaultLayout;