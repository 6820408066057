import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg'

import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';

import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { routes } from '../../routesList';

import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import addIcon from '../../assets/svg/add-icon.svg';



/**
* List of modules
* @author   Milan Stanojevic
*/
class ManageModules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            endpoints: [],
            formModules: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }
    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0 }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0 }, fields, restart));
        }
    }


    /**
    * Insert or update module
    * @author   Milan Stanojevic
    * @Objectparam    {String} name.en                      module's name on english
    * @Objectparam    {String} name.se                      module's name on swedish
    * @Objectparam    {String} link                         module's link
    * @Objectparam    {String} type                         module's type  (menu, submenu, page)
    * @Objectparam    {String} parent                       module's parent module
    * @Objectparam    {String} svgIcon                      module's icon
    * @Objectparam    {String} uDataCondition               user data condition
    * @Objectparam    {String} clinicEntryModule            clinic entry module (true, false)
    * @Objectparam    {Array} endpoints                     List of approved API endpoints
    */
    insertOrUpdate = (data) => {
        this.setState({
            loading: true,
            _saving: true
        }, () => {
            if (!this.state.form._id) {

                fetch(API_ENDPOINT + '/modules/new', {
                    method: 'PUT',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            } else {
                fetch(API_ENDPOINT + '/modules/' + this.state.form._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    this.get();
                    this.setState({
                        _saving: false
                    }, () => {
                        setTimeout(() => {
                            this.setState({ _saving: false, form: null })
                        }, 1000)
                    })
                })
            }
        })
    }

    stopAnimation = () => {
        this.player.current.play();
    }

    /**
    * Delete module
    * @author   Milan Stanojevic
    * @Objectparam    {String} id                    module's id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/modules/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })

    }



    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0 })
        }
        const defaultOptions = {
            loop: true,
            autoplay: true,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        //console.log(this.props)

        return (
            <div className="dashboard">

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="add-button" onClick={() => this.setState({
                                        form: {}
                                    })}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[{ type: 'text', name: 'name', label: 'Module name'.translate(this.props.lang), multilang: true, allowSort: true }]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} className="edit-icon" />,
                                                onClick: (item) => this.setState({ form: item })
                                            },
                                            {
                                                component: <Isvg src={garabage} className="delete-icon" />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete module'.translate(this.props.lang)} <strong>{Object.translate(this.state.deleteModal, 'name', this.props.lang)}</strong>?
                                </DeleteModal>

                            </div>
                        </Col>
                    </Row>

                </Container>
                {this.state.form ?
                    <FormBuilder dateFormat={this.props.dateFormat}
                        lang={this.props.lang}
                        onSubmit={(data) => this.insertOrUpdate(data)}
                        initialValues={this.state.form}
                        size="xl"
                        isOpen={this.state.form}
                        toggle={() => this.setState({ form: null })}
                        title={this.state.form._id ? 'Edit module'.translate(this.props.lang) : 'Add new module'.translate(this.props.lang)}
                        _saving={this.state._saving}
                        buttonConfirmText={'Saved'.translate(this.props.lang)}
                        buttonText={'Save'.translate(this.props.lang)}

                        lang={this.props.lang}
                        fields={[
                            {
                                type: 'row',
                                children: [
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name.en',
                                                label: 'Module name [en]'.translate(this.props.lang),
                                                //multilang: true,
                                                lang: this.props.lang,
                                                validate: [required('Module name is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 3,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'name.se',
                                                label: 'Module name [se]'.translate(this.props.lang),
                                                //multilang: true,
                                                lang: this.props.lang,
                                                validate: [required('Module name is required!'.translate(this.props.lang))]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'link',
                                                label: 'Link'.translate(this.props.lang),
                                                validate: [required('Link is required!'.translate(this.props.lang))],
                                                values: [{ name: 'Select link'.translate(this.props.lang), value: null }, ...routes.map((route) => { return { name: route.path, value: route.path } })]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'type',
                                                label: 'Type'.translate(this.props.lang),
                                                validate: [required('Type is required!'.translate(this.props.lang))],
                                                values: [{ name: 'Select module type'.translate(this.props.lang), value: null }, { name: 'Menu', value: 'menu' }, { name: 'Submenu', value: 'submenu' }, { name: 'Page', value: 'page' },]
                                            },
                                        ]
                                    },
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'select',
                                                name: 'parent',
                                                label: 'Parent'.translate(this.props.lang),
                                                disabled: this.props.type == 'submenu' ? false : true,
                                                values: [{ name: 'Select parent module'.translate(this.props.lang), value: null }, ...this.state.formModules.filter((module) => { return module.type == 'menu' }).map((module) => { return { name: Object.translate(module, 'name', this.props.lang), value: module._id } })]
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'image',
                                                name: 'svgIcon',
                                                label: 'Icon'.translate(this.props.lang),
                                            },
                                        ]
                                    },

                                    {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'uDataCondition',
                                                label: 'User data condition name'.translate(this.props.lang),
                                            },
                                        ]

                                    },
                                    this.props.clinicEntryModule ? {
                                        type: 'col',
                                        width: {
                                            lg: 6,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'text',
                                                name: 'clinicEntryModuleCondition',
                                                label: 'Clinic group condition name'.translate(this.props.lang),
                                            },
                                        ]

                                    } : null,
                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'checkbox',
                                                name: 'clinicEntryModule',
                                                afterText: 'Clinic entry module'.translate(this.props.lang),
                                            },
                                        ]
                                    },



                                    {
                                        type: 'col',
                                        width: {
                                            lg: 12,
                                            sm: 12,
                                            xs: 12
                                        },
                                        children: [
                                            {
                                                type: 'easy-select',
                                                name: 'endpoints',
                                                lang: this.props.lang,
                                                disableFilter: true,
                                                label: 'API Endpoints'.translate(this.props.lang),
                                                multiple: true,
                                                values: this.state.endpoints.map((item, idx) => { return { value: item.method + '|||' + item.route, name: <div className="endpoint-wrap"><span>{item.route}</span> <span className={item.method}>[{item.method}]</span> </div> } })
                                            },
                                        ]
                                    },


                                ]
                            }
                        ]}
                    ></FormBuilder>
                    :
                    null
                }

            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { type: selector(state, 'type'), clinicEntryModule: selector(state, 'clinicEntryModule') }

}, {

})(Page(ManageModules));