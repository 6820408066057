
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Col, Row } from 'reactstrap';

/**
* Generate conditions
* @author   Milan Stanojevic
*/
class Conditions extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }




    getQuestion = (id) => {
        for (let i = 0; i < this.props.questions.length; i++) {
            if (this.props.questions[i].id == id) {
                return this.props.questions[i];
            }
        }


        return {};
    }
    getAnswer = (id) => {
        for (let i = 0; i < this.props.questions.length; i++) {
            if (this.props.questions[i].answers) {
                for (let j = 0; j < this.props.questions[i].answers.length; j++)
                    if (this.props.questions[i].answers[j].id == id) {
                        return this.props.questions[i].answers[j];
                    }
            }
        }


        return {};
    }


    render() {
        let items = this.props.value && this.props.value.length > 0 ? this.props.value : [{}];
        return (
            <>
                <Row>
                    {
                        items.map((item, idx) => {
                            return (
                                <>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label size="sm">{'Show when question'.translate(this.props.lang)}</Label>
                                            <Input type="select" size="sm" value={item.conditionQuestion} onChange={(e) => {
                                                items[idx].conditionQuestion = e.target.value;
                                                this.props.onChange(items);
                                                this.forceUpdate();
                                            }}>
                                                {
                                                    [{ name: 'None', id: null }, ...this.props.questions].map((question) => {
                                                        return <option value={question.id}>{question.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label size="sm">{'is answered with'.translate(this.props.lang)}</Label>
                                            <Input type="select" size="sm" disabled={this.getQuestion(item.conditionQuestion) && this.getQuestion(item.conditionQuestion).type == 3 ? true : false} value={item.conditionAnswer} onChange={(e) => {
                                                items[idx].conditionAnswer = e.target.value;
                                                this.props.onChange(items);
                                                this.forceUpdate();
                                            }}>
                                                {item.conditionQuestion && this.getQuestion(item.conditionQuestion).answers ?
                                                    [{ name: 'None', value: null }, ...this.getQuestion(item.conditionQuestion).answers].map((answer) => {
                                                        return <option value={answer.id}>{answer.name}</option>
                                                    })
                                                    :
                                                    <option>None</option>
                                                }

                                            </Input>
                                        </FormGroup>
                                    </Col>

                                </>
                            )
                        })
                    }

                </Row>
                <FormGroup>
                    <Button color="primary" size="sm" onClick={() => {
                        items.push({});
                        this.props.onChange(items);
                        this.forceUpdate();
                    }}>{'Add new condition'.translate(this.props.lang)}</Button>
                </FormGroup>
            </>
        );
    }
}

export default Conditions;