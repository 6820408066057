import React, { Component } from "react";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";

import { Player } from "@lottiefiles/react-lottie-player";

import { Container, Row, Col, Button } from "reactstrap";

import FormBuilder from "../components/forms/formBuilder";
import { required } from "../components/forms/validation";
import { API_ENDPOINT } from "../constants";

import logoLogin from "../assets/svg/logo_login.svg";

import shapeBlue from "../assets/svg/shape-blue.svg";
import shapeGreen from "../assets/svg/shape-green.svg";
import shapeYellow from "../assets/images/shape-yellow.png";

/**
* Login page
* @author   Milan Stanojevic
*/
class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotedProjects: [],
      ...props.initialData,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](
        window.fetch,
        this.props.lang,
        this.props[0].match
      ).then((data) => {
        this.setState(
          {
            ...data,
          },
          () => {
            this.props.updateMeta(
              this.props.generateSeoTags(this.state, this.props.lang)
            );
          }
        );
      });
    }
  }

  /**
  * Login function
  * @author   Milan Stanojevic
  */
  login = (data) => {
    fetch(API_ENDPOINT + "/users/login", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.setState({
            error: result.error,
          });
        } else {
          localStorage.setItem("authToken", result.token);
          localStorage.setItem("profile", "doctor");
          localStorage.setItem("time", Math.floor(Date.now() / 1000));
          this.props.registerLog("Logged in");
          this.props.verifyUser(() => {
            if (typeof window != 'undefined' && (localStorage.getItem('groupAlias') == null || localStorage.getItem('groupAlias') == 'undefined')) {
              if (this.props.uData && this.props.uData.userLevel == 20 && this.props.uData.clinicGroups && this.props.uData.clinicGroups.length) {
                localStorage.setItem('groupAlias', this.props.uData.clinicGroups[0].alias)
              }
            }
            // if (window.location.href == `${window.location.origin}/my-data/personal-referrals/my-payments`){
            //   this.props[0].history.push("/my-data/personal-referrals/my-payments")
            // }else{
            this.props[0].history.push("/");
            // }

          });



        }
      });
  };
  componentDidUpdate(prevProps) {
    if (this.props.uData) {
      this.props[0].history.push("/");
    }
  }

  render() {
    return (
      <div className="login-main-wrap">
        <div className="login-wrapper">

          <div className="logo-header">
            <img src={logoLogin} />
          </div>

          <div className="log-in">
            <Container fluid>
              <Row>
                <Col lg={{ size: 12 }}>
                  {this.props.userVerificationInProgress ? (
                    <div className="loader-wrap">
                      <Player
                        autoplay={true}
                        loop={true}
                        src="/lf30_editor_l5cxzdyf.json"
                        style={{
                          height:
                            typeof window != "undefined" &&
                              window.innerWidth < 768
                              ? "128px"
                              : "256px",
                          width:
                            typeof window != "undefined" &&
                              window.innerWidth < 768
                              ? "128px"
                              : "256px",
                        }}
                      ></Player>
                    </div>
                  ) : (
                    <div className="panel">
                      <div className="login-container">
                        <h4>{"Login".translate(this.props.lang)} </h4>
                        <div className="login-form">
                          <FormBuilder
                            dateFormat={this.props.dateFormat}
                            lang={this.props.lang}
                            onSubmit={(data) => this.login(data)}
                            buttonText={"Log in".translate(this.props.lang)}
                            fields={[
                              {
                                type: "row",
                                children: [
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "text",
                                        name: "username",
                                        label: "Username".translate(
                                          this.props.lang
                                        ),
                                        validate: [
                                          required(
                                            "Username is required!".translate(
                                              this.props.lang
                                            )
                                          ),
                                        ],
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 12,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "password",
                                        name: "password",
                                        label: "Password".translate(
                                          this.props.lang
                                        ),
                                        validate: [
                                          required(
                                            "Password is required!".translate(
                                              this.props.lang
                                            )
                                          ),
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                              {
                                type: "row",
                                children: [
                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "checkbox",
                                        name: "remember",
                                        beforeText: (
                                          <span className="login-checkbox"></span>
                                        ),
                                        label: (
                                          <span className="remember">
                                            {"Keep me logged in".translate(
                                              this.props.lang
                                            )}
                                          </span>
                                        ),
                                      },
                                    ],
                                  },
                                  {
                                    type: "col",
                                    width: {
                                      lg: 6,
                                      sm: 12,
                                      xs: 12,
                                    },
                                    children: [
                                      {
                                        type: "hidden",
                                        name: "forget",
                                        afterText: (
                                          <a
                                            href="/forgotpassword"
                                            className="forget"
                                          >
                                            {"Forgot password?".translate(
                                              this.props.lang
                                            )}
                                          </a>
                                        ),
                                      },
                                    ],
                                  },
                                ],
                              },
                            ]}
                          ></FormBuilder>
                        </div>


                        {this.state.error ? (
                          <p className="error-message">
                            {this.state.error.translate(this.props.lang)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

export default Page(LoginPage);
