
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Row, Col } from 'reactstrap';
import Checkbox from './checkbox';
/**
* Custom select list checkbox
* @author   Stefan Pantic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null
        };
    }


    render() {

        return (
            <div>
                {this.props.label ? <Label size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}

                <div className={'list-checkbox-scroll'}>
                    <FormGroup>

                        <Row className="multiselect-checkboxes">
                            {this.props.values && this.props.values.length ? this.props.values.map((option, oidx) => {
                                if (!this.state.filter || (this.state.filter && option.name.indexOf(this.state.filter) == 0))
                                    return (
                                        <Col lg={"12"}>
                                            <FormGroup>
                                                <Checkbox disabled={this.props.disabled || option.disabled} checked={this.props.value && this.props.value.indexOf(option.value) !== -1} onChange={() => {
                                                    let value = this.props.value;
                                                    if (!value) {
                                                        value = [];
                                                    }

                                                    let idx = value.indexOf(option.value);
                                                    if (idx == -1) {
                                                        value.push(option.value);
                                                    } else {
                                                        value.splice(idx, 1);
                                                    }

                                                    this.props.onChange(value);
                                                    this.forceUpdate();

                                                }} type="checkbox" label={option.name} />
                                            </FormGroup>
                                        </Col>
                                    )
                            })
                                :
                                null
                            }
                        </Row>
                    </FormGroup>
                </div>
            </div>


        );
    }
}

export default Text;