import React from 'react';
import { reduxForm } from 'redux-form'
import { renderFields } from './fields/fields';
import {Button} from 'reactstrap';


/**
* Generate form
* @author   Milan Stanojevic
*/
class form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>

                {
                    renderFields(this.props.fields, this.props.lang)
                }

                <Button type="submit" color="primary" disabled={this.props.disabled}>{this.props.buttonText}</Button>
                {
                    this.props.additionalButton ?
                    this.props.additionalButton : null
                }
            
            </form>

        )
    }
}

export default reduxForm({
    form: 'form'  // a unique identifier for this form
})(form)
