import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import addIcon from '../../assets/svg/add-icon.svg';
import edit from '../../assets/svg/edit.svg'
import garabage from '../../assets/svg/garbage.svg'
import xIcon from '../../assets/svg/x.svg';
import ListBuilder from '../../components/listBuilder';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import FormGroup from 'reactstrap/lib/FormGroup';

class SupportCategory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            category: {}
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })
        }

        if (prevProps.selectedGroup != this.props.selectedGroup) {
            this.get();
        }

        if (prevProps.selectedClinic != this.props.selectedClinic) {
            this.get();
        }

    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic }, fields, restart));
        }
    }



    /**
    * Update support category
    * @author   Stefan Pantic
    * @Objectparam    {String} data.id      support category id
    * @Objectparam    {Object} data         support category data
    */
    update = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/update/' + data._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    categoryUpdate: null
                })
            })

        })
    }

    /**
    * Create new support category
    * @author   Stefan Pantic
    * @Objectparam    {Object} data         support category data
    */
    save = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/support-category/new', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                this.get();
                this.setState({
                    categoryModal: null,
                    category: {}

                })
            })
        })
    }

    /**
    * Delete support category
    * @author   Stefan Pantic
    * @Objectparam    {String} id         support category id
    */
    delete = (id) => {
        this.setState({
            loading: true
        }, () => {

            fetch(API_ENDPOINT + '/support-category/delete/' + id, {
                method: 'DELETE',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.get();
            })
        })
    }

    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup, clinic: this.props.selectedClinic })
        }

        let subgroups = [{ name: 'None', value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (this.state.subgroups[i].groups && this.state.subgroups[i].groups.indexOf(this.props.group) !== -1) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }

        return (
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="component-header">{this.props.linksMeta && this.props.linksMeta[this.props[0].location.pathname] ? this.props.linksMeta[this.props[0].location.pathname][this.props.lang] : ''}</h5>
                                    <button className="btn add-button" onClick={() => {
                                        // this.props[0].history.push('/personal-referrals/new')
                                        this.setState({ categoryModal: true })
                                    }}><Isvg src={addIcon} /> {'Add'.translate(this.props.lang)}</button>
                                </div>

                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: 'text', name: 'name', label: 'Name'.translate(this.props.lang), allowSort: true },

                                    ]}
                                    items={this.state.items}
                                    actions={
                                        [
                                            {
                                                component: <Isvg src={edit} />,
                                                onClick: (item) => {
                                                    this.setState({ categoryUpdate: item })
                                                    console.log(item)
                                                }
                                            },
                                            {
                                                component: <Isvg src={garabage} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                    }
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}

                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => { this.delete(this.state.deleteModal._id); this.setState({ deleteModal: null }) }}
                                >
                                    {'Delete category'.translate(this.props.lang)} <strong>{this.state.deleteModal && this.state.deleteModal.name}</strong>?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {this.state.previewModal ?
                    <Modal isOpen={this.state.previewModal} centered toggle={() => this.setState({ previewModal: !this.state.previewModal })} size="xl">
                        <ModalHeader toggle={() => this.setState({ previewModal: !this.state.previewModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewModal: !this.state.previewModal })}><Isvg src={xIcon} /></button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                        <ModalBody>
                            {/* <CreatePersonalReferralModal {...this.props} referral={{ name: this.state.previewModal.name, description: this.state.previewModal.description }} sections={this.generateSections(this.state.previewModal.items)}></CreatePersonalReferralModal> */}
                        </ModalBody>
                    </Modal>
                    :
                    null
                }

                {this.state.categoryModal ?
                    <Modal isOpen={this.state.categoryModal} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ categoryModal: !this.state.categoryModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ categoryModal: !this.state.categoryModal })}><Isvg src={xIcon} /></button>}>{'Add category'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let category = this.state.category;
                                    category.name = e.target.value;
                                    this.setState({
                                        category
                                    })
                                }} value={this.state.category.name} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.save(this.state.category)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }

                {this.state.categoryUpdate ?
                    <Modal isOpen={this.state.categoryUpdate} centered size="xl">
                        <ModalHeader toggle={() => this.setState({ categoryUpdate: !this.state.categoryUpdate })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ categoryUpdate: !this.state.categoryUpdate })}><Isvg src={xIcon} /></button>}>{'Edit category'.translate(this.props.lang)}</ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                <Input type='text' onChange={(e) => {
                                    let categoryUpdate = this.state.categoryUpdate;
                                    categoryUpdate.name = e.target.value;
                                    this.setState({
                                        categoryUpdate
                                    })
                                }} value={this.state.categoryUpdate.name} />
                            </FormGroup>
                        </ModalBody>
                        <ModalFooter style={{ margin: 'unset', justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                            <Button color="primary" onClick={() => this.update(this.state.categoryUpdate)}>{'Save'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    :
                    null
                }
            </div>
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(SupportCategory));