import React, { Component } from 'react';

import {
    Row,
    Col,
    Table,
    FormGroup, Label, Input
} from 'reactstrap';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Checkbox from './forms/fields/checkbox';
import { Player, Controls } from "@lottiefiles/react-lottie-player";

/**
* Generate data list with pagination
* @author   Milan Stanojevic
*/
class ListBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {

    }

    render() {
        let params = this.props.params;

        return (

            <Row className="list-builder-wrap">


                {
                    !this.props.hideEntries ?
                        <Col lg="6">
                            <FormGroup>
                                <Label>{'Show'.translate(this.props.lang)}
                                    <Input type="select" className="entries" value={params.entries} onChange={(e) => this.props.updateMultipleParams([{ name: 'entries', value: e.target.value }, { name: 'page', value: 0 }], true)}>
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                    </Input>
                                    {'entries'.translate(this.props.lang)}
                                </Label>
                            </FormGroup>
                        </Col>
                        :
                        null
                }


                {
                    !this.props.hideFilters ?
                        <Col lg="6" className="filter-results">
                            <FormGroup>
                                <Label>{'Filter results'.translate(this.props.lang)} <Input type="text" style={{ marginRight: 0 }} value={params.filter ? params.filter : ''} onChange={(e) => this.props.updateMultipleParams([{ name: 'filter', value: e.target.value }, { name: 'page', value: 0 }], true)} ></Input></Label>
                            </FormGroup>

                        </Col>
                        :
                        null
                }
                <Col lg="12" className="table-container">
                    <div className="table-container-scroll">
                        <Table responsive hover className={!this.props.actions ? 'cursor-row' : ''}>
                            <thead>
                                <tr style={{ whiteSpace: 'nowrap' }}>
                                    {this.props.showNumeration ? <th>#</th> : null}

                                    {
                                        this.props.fields.map((item, idx) => {
                                            return (
                                                <th className={item.allowSort ? 'sort-enabled' : null} onClick={() => {
                                                    if (item.allowSort) {
                                                        this.props.updateSort(item.name, !this.props.sortField ? 1 : this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? -1 : 1)
                                                    }
                                                }} key={idx}>{item.label} {item.allowSort ? <span className={this.props.sortField == item.name && parseInt(this.props.sortType) == 1 ? `sort sort-asc` : this.props.sortField == item.name && parseInt(this.props.sortType) == -1 ? 'sort sort-desc' : item.allowSort ? 'sort' : ''}></span> : null}</th>
                                            )
                                        })
                                    }
                                    {this.props.actions ?
                                        <th className="action-td">{this.props.actionLabel ? this.props.actionLabel : 'Action'.translate(this.props.lang)}</th>
                                        :
                                        null}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.props.items && this.props.items.length ? this.props.items.map((item, idx) => {
                                        return (
                                            <tr key={idx} onClick={() => {
                                                if (!this.props.actions && this.props.onClick) {
                                                    if (this.props.rawItems)
                                                        this.props.onClick(this.props.rawItems[idx])
                                                    else
                                                        this.props.onClick(item)
                                                }
                                            }}>
                                                {this.props.showNumeration ? <th scope="row">{params.page * params.entries + idx + 1}</th> : null}
                                                {
                                                    this.props.fields.map((field, fidx) => {
                                                        if (field.type == 'two-rows-text') {
                                                            return (
                                                                <td key={fidx}>
                                                                    {
                                                                        field.strong && field.strong(item) ?
                                                                            <>
                                                                                <strong> {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''}  {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)}</strong> <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {field.beforeText && field.beforeText.condition(item) ? field.beforeText.text : ''} {field.multilang ? Object.translate(item, field.name, this.props.lang) : Object.get(item, field.name)} <br /><span className="clinic-subgroup-name">{Object.get(item, field.secondRowName)}</span>

                                                                            </>
                                                                    }

                                                                </td>
                                                            )
                                                        } else if (field.type == 'text') {
                                                            return (
                                                                <td key={fidx}>{field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]}</td>
                                                            )
                                                        } else if (field.type == 'checkbox') {
                                                            return (
                                                                <td key={fidx}>
                                                                    <div>
                                                                        <Checkbox checked={item[field.name]} label={field.multilang ? item[field.name] && item[field.name][this.props.lang] : item[field.name]} />
                                                                    </div>
                                                                </td>
                                                            )
                                                        } else if (field.type == 'list') {
                                                            return (
                                                                <td key={fidx}>
                                                                    {
                                                                        item[field.name] ? item[field.name].map((fitem, fitemidx) => {
                                                                            return (
                                                                                <div className="list-item">
                                                                                    {fitem[field.itemName]}

                                                                                    {
                                                                                        field.actions.map((action, aidx) => {
                                                                                            return (
                                                                                                <button key={aidx} onClick={() => action.onClick(item, fitemidx)}>{action.component}</button>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                            :
                                                                            null
                                                                    }
                                                                </td>
                                                            )

                                                        }
                                                    })
                                                }
                                                {this.props.actions ?
                                                    <td className="action-td">
                                                        {
                                                            this.props.actions && this.props.actions.length ?
                                                                this.props.actions.map((action, aidx) => {
                                                                    if (!action.condition || (action.condition && action.condition(item)))
                                                                        return (
                                                                            <button key={aidx} onClick={() => {
                                                                                if (this.props.rawItems) {
                                                                                    action.onClick(this.props.rawItems[idx]);
                                                                                }
                                                                                else {
                                                                                    action.onClick(item)
                                                                                }
                                                                            }}>{action.component}</button>
                                                                        )
                                                                })
                                                                :
                                                                null
                                                        }
                                                    </td>
                                                    :
                                                    null
                                                }



                                            </tr>
                                        )
                                    })
                                        :
                                        null

                                }
                            </tbody>
                        </Table>
                    </div>
                    {this.props.loading ?
                        <div className="loader-wrap" >
                            <Player
                                autoplay={true}
                                loop={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                        :
                        null
                    }

                </Col>
                {
                    !this.props.hidePagination ?
                        <Col lg="6">
                            <ReactPaginate
                                previousLabel={'Previous'.translate(this.props.lang)}
                                nextLabel={'Next'.translate(this.props.lang)}
                                breakLabel={'...'}
                                breakClassName={'page-item disabled'}
                                breakLinkClassName={'page-link disabled'}
                                pageCount={this.props.total / params.entries}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={(page) => { this.props.updateParams('page', page.selected) }}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}

                                pageClassName={'page-item'}
                                pageLinkClassName={'page-link'}
                                previousClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextClassName={'page-item'}
                                nextLinkClassName={'page-link'}
                                activeClassName={'active'}
                                forcePage={parseInt(params.page)}
                            />
                        </Col>
                        :
                        null
                }
                {
                    !this.props.hidePagination ?
                        <Col lg="6">
                            <span className="news-counter" >{'Showing'.translate(this.props.lang)}  <span className="colored-green"> {this.props.total ? (params.page * params.entries + 1) : 0} </span> {'to'.translate(this.props.lang)} <span className="colored-green"> {this.props.total < (params.page * params.entries + params.entries * 1) ? this.props.total : (params.page * params.entries + params.entries * 1)} </span>  {'of'.translate(this.props.lang)} <span className="colored-blue"> {this.props.total} </span>  {'entries'.translate(this.props.lang)}</span>
                        </Col>
                        :
                        null
                }

            </Row>

        );
    }
}

export default ListBuilder;