
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Button, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

/**
* Generate answers
* @author   Milan Stanojevic
*/
class Answers extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        let items = this.props.value ? this.props.value : [{id: uuidv4()}, {id: uuidv4()}];
        return (
            <>
                <Row>
                    {
                        items.map((item, idx) => {
                                return (
                                    <>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label size="sm">{'Answer'.translate(this.props.lang)}</Label>
                                                <Input type="text" size="sm" value={item.name} onChange={(e) => {
                                                    if (!items[idx]){
                                                        items[idx] = {
                                                            id: uuidv4()
                                                        };
                                                    }

                                                    items[idx].name = e.target.value;
                                                    this.props.onChange(items);
                                                    this.forceUpdate();
                                                }}>

                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="8">
                                            <FormGroup>
                                                <Label size="sm">{'Printable answer'.translate(this.props.lang)}</Label>
                                                <Input size="sm" disabled={this.props.disablePrintableAnswer} type="text" value={item.text} onChange={(e) => {
                                                    if (!items[idx]){
                                                        items[idx] = {
                                                            id: uuidv4()
                                                        };
                                                    }

                                                    items[idx].text = e.target.value;
                                                    this.props.onChange(items);
                                                    this.forceUpdate();
                                                }}>

                                                </Input>
                                            </FormGroup>
                                        </Col>

                                    </>
                                )
                            })
                    }

                </Row>
                <FormGroup>
                    <Button color="primary" size="sm" onClick={() => {
                        items.push({id: uuidv4()});
                        this.props.onChange(items);
                        this.forceUpdate();
                    }}>{'Add answer'.translate(this.props.lang)}</Button>
                </FormGroup>
            </>
        );
    }
}

export default Answers;