import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';

import logo from '../assets/svg/logo.svg';

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <footer>
                <Container>
                    <Row>
                    </Row>
                </Container>
            </footer>
        )
    }
}

export default Footer;