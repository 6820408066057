
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText, Row, Col, Form } from 'reactstrap';

/**
* Generate options
* @author   Milan Stanojevic
*/
class Options extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <Row>
                {
                    this.props.values.map((item, idx) => {
                        return (
                            <Col lg={item.width.lg} sm={item.width.sm} xs={item.width.xs}>
                                <FormGroup className="referal-checkbox radio-short">
                                    <Label>
                                       <Input checked={this.props.value == item.value} type="radio" onChange={() => this.props.onChange(item.value)} /> <span className="field-after-text">{item.name}</span>
                                    </Label>
                                </FormGroup>
                            </Col>

                        )
                    })
                }
            </Row>
        )

    }
}

export default Options;