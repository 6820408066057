import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import { FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';

import hide from '../../../assets/svg/hide.svg';
import show from '../../../assets/svg/show.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

/**
* Phone field
* @author   Milan Stanojevic
*/
class Text extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {
        return (
            <FormGroup check={this.props.type == 'checkbox'}>
                {this.props.label ? <Label check={this.props.type == 'checkbox'} size="sm" className={this.props.error ? "required" : ""}>{this.props.label}{this.props.haveValidation ? <span className="required-label-span">*</span> : null}</Label> : null}
                <PhoneInput

                    disabled={this.props.disabled}
                    country={'de'}
                    value={this.props.value}
                    enableLongNumbers={true}
                    onChange={(e, data) => {
                        // console.log(data)
                        if (e.indexOf('+') !== 0){
                            e = '+' + e;
                        };

                        if (e.indexOf(data.dialCode + '0') == 1){
                            e = e.replace(data.dialCode + '0', data.dialCode)
                        }

                        this.props.onChange(e)
                    }}
                />

                {this.props.error ? <FormFeedback >{this.props.error}</FormFeedback> : null}
            </FormGroup>
        );
    }
}

export default Text;